import React from "react";
import {List, Space} from "antd";
import {CalendarOutlined, PlusOutlined, RightOutlined} from "@ant-design/icons";
import noImage from '../../assets/images/no-image.jpg';
import useCourses from "./useCourses";

// Types
import {CourseType} from "../../types/CourseTypes";

// Components
import ButtonComponent from "../subComponents/ButtonComponent";
import CourseFormModal from "./formModal/CourseFormModal";
import CardComponent from "../subComponents/CardComponent";
import SectionsModal from "./sections/SectionsModal";
import CoursesList from "./subComponents/CoursesList";
import InstructorsModal from "./instructors/InstructorsModal";

// Utils
import {DATE_FORMAT, getDateFormat} from "../../utils/generalUtils";
import {routes} from "../../utils/routes";


type CoursesType = {
  canCreate: boolean;
  canUpdate: boolean;
  canRemove: boolean;
};
const Courses = (props: CoursesType) => {
  const {
    pagination, formModalVisible, sectionsModalVisible, instructorsModalVisible, courseToEdit, isStudent,
    response, isSmallScreen, setPagination, handleCourseFormModal, handleCourseSectionsModal,
    handleCourseInstructorsModal, handleCourseDetail, getCardActions
  } = useCourses(props);

  return (
    <>
      <CoursesList showRedirectContent={isStudent}
                   showExtraButtons={props.canCreate}
                   sectionTitle={'CURSOS'}
                   response={response}
                   redirectRoute={routes.courses.myCourses}
                   pagination={pagination}
                   redirectContent={
                     <>
                         <span className={'group-hover:underline font-semibold group-hover:text-complementary'}>
                           Mis cursos
                         </span> <RightOutlined className={'group-hover:text-complementary'} />
                     </>
                   }
                   extraButtonsContent={
                     <ButtonComponent text={'Nuevo curso'}
                                      icon={<PlusOutlined />}
                                      className={'ant-btn-primary'}
                                      onClick={() => handleCourseFormModal(true)} />
                   }
                   setPagination={setPagination}
                   renderItem={
                     (course: CourseType) => (
                       <List.Item className={'h-full contents'}
                                  onClick={() => handleCourseDetail(course.courseId)}>
                         <CardComponent noImageUrl={noImage}
                                        hoverable={isStudent}
                                        bodyHeight={isSmallScreen ? '160px' : '200px'}
                                        item={course}
                                        actions={getCardActions(course)}
                                        description={
                                          <Space direction={'vertical'}>
                                            <p className={`${isSmallScreen ? 'h-16' : 'h-24' } 
                                              line-clamp-3 ${isStudent ? 'sm:line-clamp-6' : 'sm:line-clamp-4' } 
                                              text-justify`}>
                                              {course.description}
                                            </p>
                                            {!isStudent &&
                                              <p className={'text-tertiary'}>
                                                <CalendarOutlined />
                                                {` ${getDateFormat(course.releaseDate, DATE_FORMAT)}`}
                                              </p>
                                            }
                                          </Space>
                                        } />
                       </List.Item>
                     )
                   } />

      {formModalVisible &&
        <CourseFormModal courseToEdit={courseToEdit}
                         onToggleModal={handleCourseFormModal} />
      }
      {sectionsModalVisible &&
        <SectionsModal canUpdate={props.canUpdate}
                       courseDetail={courseToEdit}
                       onToggleModal={handleCourseSectionsModal} />
      }
      {instructorsModalVisible &&
        <InstructorsModal canUpdate={props.canUpdate}
                          courseToEdit={courseToEdit}
                          onToggleModal={handleCourseInstructorsModal} />
      }
    </>
  );
}

export default Courses;