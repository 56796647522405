// Libraries
import {Navigate, Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Layout} from 'antd';

// Store
import * as sessionSelectors from '../../store/redux/selectors/accountSelectors';

// Components
import Login from "../account/login/Login";
import Register from "../account/register/Register";
import ForgotPassword from "../account/forgotPassword/ForgotPassword";
import ResetPassword from "../account/resetPassword/ResetPassword";
import Alliances from "../alliances/Alliances";
import Geeks from "../geeks/Geeks";
import Courses from "../courses/Courses";
import Students from "../students/Students";
import Profile from "../subComponents/profile/Profile";
import MyCourses from "../courses/myCourses/MyCourses";
import CourseDetail from "../courses/detail/CourseDetail";
import Instructors from "../instructors/Instructors";
import ResourceDetail from "../courses/resourceDetail/ResourceDetail";

// Utils
import {getLocalStorageItem, ROLES} from "../../utils/generalUtils";
import {routes} from '../../utils/routes';

// Constants and Functions
const {Content} = Layout;


function Router() {
  const isLoggedIn = useSelector(sessionSelectors.isLoggedInSelector);
  const currentRole = getLocalStorageItem('render_role');
  const roleAccess = ROLES.find(role => role.roleName === currentRole)!;
  const allowed_modules = isLoggedIn ? roleAccess.modules.map(route => route.route) : [];

  const getModulePermissions = (route: string) => {
    const { create, update, remove } = roleAccess.modules.find(module => module.route === route)!;

    return { canCreate: create, canUpdate: update, canRemove: remove };
  }

  return (
    <Content className={`flex h-full ${isLoggedIn && 'bg-banner-background bg-contain bg-bottom'}`}>
      <Routes>
        {isLoggedIn
          ?
          <>
            {allowed_modules.includes(routes.alliances.root) &&
              <Route path={routes.alliances.root}
                     element={<Alliances {...getModulePermissions(routes.alliances.root)} />} />
            }
            {allowed_modules.includes(routes.admins.root) &&
              <Route path={routes.admins.root}
                     element={<Geeks />} />
            }
            {allowed_modules.includes(routes.courses.root) &&
              <Route path={routes.courses.root}
                     element={<Courses {...getModulePermissions(routes.courses.root)} />} />
            }
            {allowed_modules.includes(routes.courses.myCourses) &&
              <Route path={routes.courses.myCourses}
                     element={<MyCourses />} />
            }
            {allowed_modules.includes(routes.courses.courseDetail) &&
              <Route path={routes.courses.courseDetail}
                     element={<CourseDetail />} />
            }
            {allowed_modules.includes(routes.courses.resourceDetail) &&
              <Route path={routes.courses.resourceDetail}
                     element={<ResourceDetail />} />
            }
            {allowed_modules.includes(routes.students.root) &&
              <Route path={routes.students.root}
                     element={<Students />} />
            }
            {allowed_modules.includes(routes.instructors.root) &&
              <Route path={routes.instructors.root}
                     element={<Instructors />} />
            }
            {allowed_modules.includes(routes.profile) &&
              <Route path={routes.profile}
                     element={<Profile />} />
            }

            <Route path={'*'} element={<Navigate to={allowed_modules[0]} />} />
          </>
          :
          <>
            <Route path={routes.account.login} element={<Login />} />
            <Route path={routes.account.register} element={<Register />} />
            <Route path={routes.account.forgotPassword} element={<ForgotPassword />} />
            <Route path={routes.account.resetPassword} element={<ResetPassword />} />

            <Route path={routes.adminAccount.login} element={<Login />} />
            <Route path={routes.adminAccount.register} element={<Register />} />
            <Route path={routes.adminAccount.forgotPassword} element={<ForgotPassword />} />
            <Route path={routes.adminAccount.resetPassword} element={<ResetPassword />} />

            <Route path={'*'} element={<Navigate to={routes.account.login} />} />
          </>
        }
      </Routes>
    </Content>
  );
}

export default Router;
