// Libraries
import React, {useState} from 'react';
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from "../../../../store/store";
import {getAll} from "../../../../store/redux/actions/instructorActions";
import * as instructorSelectors from "../../../../store/redux/selectors/instructorSelectors";

// Types
import {FiltersPaginationType} from "../../../../types/SharedTypes";


const useInstructorFormModal = () => {
  const dispatch = useAppDispatch();
  const [pagination, setPagination] = useState<FiltersPaginationType>({
    paginationVm: {page: 1, pageSize: 100},
    filterVm: { textFilter: "" },
  });
  const { collection } = useSelector(instructorSelectors.allInstructorsSelector)!;

  const handleFetchInstructors = (text: string) => {
    if(text.length === 3){
      const newPagination = {
        paginationVm: {page: 1, pageSize: 100},
        filterVm: { textFilter: text },
      };
      setPagination(newPagination);
      dispatch((getAll(newPagination)));
    }
  }

  return {
    collection,
    handleFetchInstructors,
  }
}

export default useInstructorFormModal;