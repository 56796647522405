// Constants
import {resourceTypes} from '../types';
import {ResourceReducerType} from "../../../types/ResourceTypes";

const initialState = {
  resourceParams: {
    resourceTypes: []
  },
  resources: [],
};

const resourceReducer = (state = initialState, action: ResourceReducerType) => {
  switch (action.type) {
    case resourceTypes.GET_RESOURCE_PARAMS:
      return {
        ...state,
        resourceParams: action.resourceParams
      };
    case resourceTypes.GET_ALL_RESOURCES:
      return {
        ...state,
        resources: action.resources
      };
    default:
      return state;
  }
};

export default resourceReducer;
