// Libraries
import React, {useEffect} from 'react';
import {Form} from 'antd';
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from '../../../store/store';
import { add, update } from '../../../store/redux/actions/studentActions';
import * as studentSelectors from "../../../store/redux/selectors/studentSelectors";

// Types
import {StudentType} from "../../../types/StudentTypes";
import {getLocalStorageItem, ROLE_NAMES} from "../../../utils/generalUtils";


type PropsType = {
  studentToEdit?: StudentType;
  selectedAllianceId: string | null;
  onToggleModal: (visible: boolean) => void;
};
const useStudentsFormModal = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const {studentToEdit, selectedAllianceId, onToggleModal} = props;
  const [form] = Form.useForm();
  const isEditing = studentToEdit !== undefined;
  const isAlliance = getLocalStorageItem('render_role') === ROLE_NAMES.ALLIANCE;
  const alliances = useSelector(studentSelectors.allianceParamsSelector)!;
  const allianceName = isAlliance
    ? getLocalStorageItem('user_name')
    : alliances.find(alliance => alliance.customId === selectedAllianceId)!.name;

  useEffect(() => {
    if(isEditing) {
      form.setFieldsValue({
        isActive: studentToEdit?.isActive,
        name: studentToEdit?.name,
        email: studentToEdit?.email,
        alliance: allianceName,
      });
    } else {
      form.setFieldsValue({
        alliance: allianceName,
      });
    }
  }, []);

  const handleSubmit = (student: StudentType) => {
    delete student.alliance;

    if(isEditing)
      student.studentId = studentToEdit?.studentId;
    if (!isAlliance)
      student.allianceId = selectedAllianceId!;

    isEditing ? dispatch(update(student)) : dispatch(add(student));
    onToggleModal(false);
  }

  return {
    form,
    isEditing,
    handleSubmit,
  }
}

export default useStudentsFormModal;