import request from './requestWrapper';

// Types
import {CourseSectionType} from "../types/CourseSectionTypes";


function getAllByCourseId(courseId: string) {
  return request({
    url: 'CourseSection/GetAllByCourseId',
    method: 'GET',
    params: { courseId }
  });
}
function add(courseSection: CourseSectionType) {
  return request({
    url: 'CourseSection/Add',
    method: 'POST',
    data: courseSection
  });
}
function update(courseSection: CourseSectionType) {
  return request({
    url: 'CourseSection/Update',
    method: 'PUT',
    data: courseSection
  });
}
function remove(courseSection: CourseSectionType) {
  return request({
    url: 'CourseSection/Delete',
    method: 'DELETE',
    data: courseSection
  });
}

export default {
  getAllByCourseId, add, update, remove
}