// Constants
import {courseSectionTypes} from '../types';
import {CourseSectionReducerType} from "../../../types/CourseSectionTypes";

const initialState = {
  courseSectionResponse: []
};

const courseSectionReducer = (state = initialState, action: CourseSectionReducerType) => {
  switch (action.type) {
    case courseSectionTypes.GET_ALL_COURSE_SECTIONS:
      return {
        ...state,
        courseSectionResponse: action.courseSectionResponse
      };
    default:
      return state;
  }
};

export default courseSectionReducer;
