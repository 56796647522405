// Constants
import {courseStudentTypes} from '../types';
import {CourseStudentReducerType} from "../../../types/CourseStudentTypes";

const initialState = {
  courseStudentResponse: {
    page: 1, pageSize: 12, rowCount: 0, collection: []
  }
};

const courseStudentReducer = (state = initialState, action: CourseStudentReducerType) => {
  switch (action.type) {
    case courseStudentTypes.GET_ALL_COURSE_STUDENT:
      return {
        ...state,
        courseStudentResponse: action.courseStudentResponse
      };
    default:
      return state;
  }
};

export default courseStudentReducer;
