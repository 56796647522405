// Store
import {AppDispatch} from '../../store';

// Types
import {ExamType} from "../../../types/ResourceTypes";

// Store
import {setLoading} from "./generalActions";
import {courseStudentResourceTypes} from "../types";
import courseStudentResourceServices from '../../../api/courseStudentResourceServices';

// Components
import {ERROR_MODAL} from "../../../components/subComponents/responseModals";


export const resetExamScore = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: courseStudentResourceTypes.RESET_EXAM_SCORE
  });
}
export const completeResource = (resourceId: string) => async (dispatch: AppDispatch) => {
  courseStudentResourceServices.completeResource(resourceId)
    .then(() => {

    })
    .catch(err => {
      console.log(err.data);
    })
}
export const completeExam = (exam: ExamType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseStudentResourceServices.completeExam(exam)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: courseStudentResourceTypes.GET_EXAM_SCORE,
        examScore: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}