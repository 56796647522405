// Libraries
import React, {useEffect, useState} from 'react';
import {Form} from 'antd';
import {BreadcrumbItemType, BreadcrumbSeparatorType} from "antd/es/breadcrumb/Breadcrumb";
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from '../../../store/store';
import {add, getAllByCourseId, remove, update} from "../../../store/redux/actions/courseSectionActions";
import {add as addResource, update as updateResource, remove as removeResource} from "../../../store/redux/actions/resourceActions";
import * as courseSectionSelectors from "../../../store/redux/selectors/courseSectionSelectors";

// Types
import {CourseType} from "../../../types/CourseTypes";
import {CourseSectionType} from "../../../types/CourseSectionTypes";
import {ResourceType} from "../../../types/ResourceTypes";

// Components
import {ERROR_MODAL} from "../../subComponents/responseModals";


type PropsType = {
  courseDetail?: CourseType;
  onToggleModal: (visible: boolean) => void;
};
const useSectionsModal = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const [breadcrumbItems, setBreadcrumbItems] =
    useState<Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] | undefined>(
      [{
        title: <span className={'cursor-pointer'}>Información del curso</span>,
        onClick: () => handleSteps(1)
      }]
    );
  const [modalKey, setModalKey] = useState(1);
  const [sectionForm] = Form.useForm();
  const [resourceForm] = Form.useForm();
  const {courseDetail, onToggleModal} = props;
  const [courseSectionToEdit, setCourseSectionToEdit] = useState<CourseSectionType | undefined>(undefined);
  const [resourceToEdit, setResourceToEdit] = useState<ResourceType | undefined>(undefined);
  const courseSectionResponse = useSelector(courseSectionSelectors.allCourseSectionsSelector)!;

  useEffect(() => {
    dispatch(getAllByCourseId(courseDetail?.courseId!));
  }, [modalKey]);

  const handleEditSection = (section: CourseSectionType) => {
    setCourseSectionToEdit(section);
    handleSteps(2, 'Editar sección');
  }

  const handleRemoveSection = (section: CourseSectionType) => {
    dispatch(remove(section));
  }

  const handleSectionDetail = (section: CourseSectionType) => {
    setCourseSectionToEdit(section);
    handleSteps(2, 'Detalle de sección');
  }

  const handleEditResource = (resource: ResourceType) => {
    handleSteps(3, 'Editar recurso');
    setResourceToEdit(resource);
  }

  const handleRemoveResource = (resource: ResourceType) => {
    dispatch(removeResource(resource));
  }

  const handleSteps = (newStep: number, stepTitle: string = '') => {
    const newBreadcrumbItems = breadcrumbItems;
    const currentStep = newBreadcrumbItems!.length;
    const isNewStep = currentStep < newStep;

    if (isNewStep) {
      newStep === 2 && sectionForm.resetFields();
      newStep === 3 && resourceForm.resetFields();
      newBreadcrumbItems!.push({ title: <span className={'cursor-pointer'}>{stepTitle}</span>, onClick: () => handleSteps(newStep) });
    } else {
      newStep < 2 && setCourseSectionToEdit(undefined);
      newStep < 3 && setResourceToEdit(undefined);
      newBreadcrumbItems!.length = newStep;
    }
    setBreadcrumbItems(newBreadcrumbItems);
    setModalKey(modalKey + 1);
  };

  const handleSubmit = () => {
    const isCourseSection = breadcrumbItems?.length === 2;
    let newValues = isCourseSection ? sectionForm.getFieldsValue() : resourceForm.getFieldsValue();

    if(Object.values(newValues).includes(undefined) || JSON.stringify(newValues).includes('null')) {
      ERROR_MODAL('No se pudieron guardar los cambios porque hay campos sin completar.');
      return;
    }

    if(isCourseSection) {
      newValues.courseId = courseDetail?.courseId;

      if(courseSectionToEdit){
        newValues.courseSectionId = courseSectionToEdit?.courseSectionId;
        dispatch(update(newValues));
      } else {
        dispatch(add(newValues));
      }
    } else {
      newValues.courseSectionId = courseSectionToEdit?.courseSectionId;

      if(resourceToEdit){
        newValues.resourceId = resourceToEdit?.resourceId;
        dispatch(updateResource(newValues));
      } else {
        dispatch(addResource(newValues));
      }
    }

    handleSteps(breadcrumbItems!.length - 1);
  }

  return {
    breadcrumbItems,
    modalKey,
    sectionForm,
    resourceForm,
    courseSectionToEdit,
    resourceToEdit,
    courseSectionResponse,
    handleEditSection,
    handleRemoveSection,
    handleSectionDetail,
    handleEditResource,
    handleRemoveResource,
    handleSteps,
    handleSubmit,
  }
}

export default useSectionsModal;