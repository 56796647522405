import React from "react";
import {Col, Image, Popconfirm, Row, Space, Tabs} from "antd";
import {CheckCircleOutlined} from "@ant-design/icons";
import baseURL from "../../../api/baseURL";
import noImage from '../../../assets/images/no-image.jpg';
import useCourseDetail from "./useCourseDetail";

// Components
import ButtonComponent from "../../subComponents/ButtonComponent";

// Utils
import {isEmpty} from "../../../utils/generalUtils";


const CourseDetail = () => {
  const {courseDetail, tabItems, isSmallScreen, handleJoinCourse} = useCourseDetail();

  return (
    <Row className={'w-full flex bg-secondaryDark bg-opacity-80'}>
      <Col md={24} xs={24} className={'bg-snow'}>
        {!isEmpty(courseDetail) &&
          <>
            <Col className={'grid grid-cols-2 grid-rows-auto gap-4 gap-y-5 lg:gap-y-10 py-10 px-5 sm:px-8 lg:px-20'}>
              <Col className={'col-span-2 lg:col-span-1 sm:row-start-2 lg:row-start-1'}>
                <Space direction={'vertical'} size={'small'} className={'w-full'}>
                  <p className={'text-3xl sm:text-4xl lg:text-6xl text-tertiaryDark'}>
                    {courseDetail.course.name}
                  </p>
                  <p className={'pt-0 sm:pt-5 lg:pt-10 text-lg sm:text-md sm:text-xl text-tertiary'}>
                    {courseDetail.course.description}
                  </p>
                </Space>
              </Col>

              <Col className={'hidden text-center sm:block col-span-2 lg:col-span-1 sm:row-start-1 w-full p-0 flex ' +
                '[&>div]:h-full'}>
                <Image preview={false}
                       className={'w-full h-full object-cover'}
                       src={courseDetail.course.imageUrl
                         ? baseURL + courseDetail.course.imageUrl!.replaceAll('\\', '/')
                         : noImage} />
              </Col>

              <Col className={'pt-5 bg-center col-span-2 text-center'}>
                {courseDetail.isEnrolled
                  ?
                  <span className={'text-tertiaryLight text-lg'}>
                    <CheckCircleOutlined /> Ya estás inscrito a este curso
                  </span>
                  : <Popconfirm title={`¿Confirmas que deseas unirte a ${courseDetail.course.name}?`}
                                okText={'Confirmar'} onConfirm={handleJoinCourse}>
                    <ButtonComponent text={'Únete a este curso'}
                                     className={'ant-btn-primary'} />
                  </Popconfirm>
                }
              </Col>
            </Col>

            <Col md={24} xs={24} className={'min-h-[50%] sm:min-h-fit bg-snowDark py-5 px-5 sm:px-8 lg:px-20'}>
              <Tabs defaultActiveKey={"1"}
                    centered={isSmallScreen}
                    items={tabItems} />
            </Col>
          </>
        }
      </Col>
    </Row>
  );
}

export default CourseDetail;