import request from './requestWrapper';

// Types
import {FiltersPaginationType} from "../types/SharedTypes";
import {StudentType} from "../types/StudentTypes";
import {InstructorType} from "../types/InstructorTypes";


function getProfile() {
  return request({
    url: 'Instructor/GetProfile',
    method: 'GET',
  });
}
function getAll(pagination: FiltersPaginationType) {
  return request({
    url: 'Instructor/GetAll',
    method: 'POST',
    data: pagination
  });
}
function add(instructor: InstructorType) {
  return request({
    url: 'Instructor/Add',
    method: 'POST',
    data: instructor
  });
}
function update(instructor: InstructorType) {
  return request({
    url: 'Instructor/Update',
    method: 'PUT',
    data: instructor
  });
}
function updateProfile(instructor: FormData) {
  return request({
    url: 'Instructor/UpdateProfile',
    method: 'PUT',
    data: instructor
  });
}

export default {
  getProfile, getAll, add, update, updateProfile
}