// Types
import {courseStudentResourceTypes} from '../types';
import {CourseStudentResourceReducerType} from "../../../types/CourseStudentResourceTypes";

const initialState = {
  examScore: {
    graded: false,
    result: [],
    score: 0,
  }
};

const courseStudentResourceReducer = (state = initialState, action: CourseStudentResourceReducerType) => {
  switch (action.type) {
    case courseStudentResourceTypes.RESET_EXAM_SCORE:
      return {
        ...state,
        examScore: {
          graded: false,
          result: [],
          score: 0,
        }
      };
    case courseStudentResourceTypes.GET_EXAM_SCORE:
      return {
        ...state,
        examScore: { graded: true, ...action.examScore }
      };
    default:
      return state;
  }
};

export default courseStudentResourceReducer;