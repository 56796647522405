import React from "react";
import {Card, Col, List, Row, Space} from "antd";
import {CheckOutlined, CloseOutlined, MailOutlined, PlusOutlined} from "@ant-design/icons";
import useInstructors from "./useInstructors";

// Components
import ButtonComponent from "../subComponents/ButtonComponent";
import InstructorsFormModal from "./formModal/InstructorsFormModal";
import TableComponent from "../subComponents/TableComponent";

// Utils
import {tableColumns} from "../../utils/tableColumns";

// Types
import {InstructorType} from "../../types/InstructorTypes";


const Instructors = () => {
  const {
    formModalVisible, instructorToEdit, page, pageSize, rowCount, collection, isSmallWindow,
    handleInstructorModal, handleActionButtons,
  } = useInstructors();

  return (
    <Row className={'w-full flex bg-secondaryDark bg-opacity-80'}>
      <Col md={24} xs={24} className={'flex bg-[transparent] py-20'}>
        <p className={'text-center m-auto text-3xl font-bold text-white'}>INSTRUCTORES</p>
      </Col>
      <Col md={24} xs={24} className={'bg-snow py-0 md:py-10 px-5 sm:px-10 md:px-20'}>
        <Col md={24} xs={24} className={'text-right my-5 md:mt-0 md:mb-10'}>
          <ButtonComponent text={'Nuevo instructor'} 
                           icon={<PlusOutlined />}
                           className={'ant-btn-primary'}
                           onClick={() => handleInstructorModal(true)} />
        </Col>

        {isSmallWindow
          ? <List grid={{ gutter: 16, sm: 2, xs: 1 }}
                  dataSource={collection}
                  renderItem={(instructor: InstructorType, index) => (
                    <List.Item>
                      <Card hoverable
                            bordered={true}
                            className={'border-smokeExtraDark [&>div.ant-card-cover]:m-0'}
                            actions={[
                              handleActionButtons(instructor)
                            ]}
                      >
                        <Card.Meta title={<span title={instructor.name}>{instructor.name}</span>}
                                   description={
                                     <Space direction={'vertical'}>
                                         <span className={'font-semibold'} title={instructor.email}>
                                           <MailOutlined /> {instructor.email}
                                         </span>
                                       <span className={`font-semibold ${instructor.isActive ? 'text-tertiary' : 'text-complementary'}`}>
                                           {instructor.isActive
                                             ? <span><CheckOutlined /> {'Activo'}</span>
                                             : <span><CloseOutlined /> {'Inactivo'}</span>
                                           }
                                         </span>
                                     </Space>} />
                      </Card>
                    </List.Item>
                  )}
          />
          : <TableComponent pageSize={pageSize}
                            className={'shadow-md'}
                            currentPage={page}
                            total={rowCount}
                            scrollX={800}
                            filterText={''}
                            rowKey={'instructorId'}
                            dataSource={collection}
                            columns={tableColumns('students', handleActionButtons)}
                            onChangePage={(pagination) => console.log(pagination)} />
        }
      </Col>

      {formModalVisible &&
        <InstructorsFormModal instructorToEdit={instructorToEdit}
                              onToggleModal={handleInstructorModal} />
      }
    </Row>
  );
}

export default Instructors;