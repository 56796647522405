// Constants
import {courseTypes} from '../types';
import {CourseReducerType} from "../../../types/CourseTypes";

const initialState = {
  courseResponse: {
    page: 1, pageSize: 12, rowCount: 0, collection: []
  },
  courseDetail: {
    courseInstructors: [], courseSections: [], course: {}, enrolledStudents: 0, isEnrolled: false, progress: 0
  }
};

const courseReducer = (state = initialState, action: CourseReducerType) => {
  switch (action.type) {
    case courseTypes.RESET_COURSE_DETAIL:
      return {
        ...state,
        courseDetail: {
          courseInstructors: [], courseSections: [], course: {}, enrolledStudents: 0, isEnrolled: false, progress: 0
        }
      };
    case courseTypes.GET_COURSE_DETAIL:
      return {
        ...state,
        courseDetail: action.courseDetail
      };
    case courseTypes.GET_ALL_COURSES:
      return {
        ...state,
        courseResponse: action.courseResponse
      };
    default:
      return state;
  }
};

export default courseReducer;
