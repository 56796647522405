import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

// Store
import {useAppDispatch} from '../../store/store';
import * as generalSelectors from '../../store/redux/selectors/generalSelectors';
import {setCurrentRoute} from '../../store/redux/actions/generalActions';

// Utils
import {routes} from '../../utils/routes';
import {
  clearStorage, getLocalStorageItem, getWindowInformation, redirectWindow,
  ROLE_NAMES, ROLES, useScreenSizeValidator
} from '../../utils/generalUtils';


const useNavbar = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isSmallWindow = useScreenSizeValidator('(max-width: 767px)');
  const userName = getLocalStorageItem('user_name') ?? '';
  const userNameAcronym = userName.split(/\s/)
    .reduce((response, word, index) => response += index < 2 ? word.slice(0,1) : '', '');
  let currentRoute = useSelector(generalSelectors.currentRouteSelector)!;
  const secondSlashIndex = currentRoute.indexOf('/', 1);
  currentRoute = secondSlashIndex === -1 ? currentRoute : currentRoute.substring(0, secondSlashIndex);
  const currentRole = getLocalStorageItem('render_role');
  const allowed_modules = ROLES.find(role => role.roleName === currentRole)!.modules.map(route => route.route);
  const isStudent = currentRole === ROLE_NAMES.STUDENT;

  useEffect(() => {
    const { pathname } = getWindowInformation();

    dispatch(setCurrentRoute(pathname));
  }, [currentRoute]);

  const handleRouteSelector = (route: string) => {
    setDrawerVisible(false);
    if(route === "logout"){
      handleLogout();
    } else {
      dispatch(setCurrentRoute(route));
      navigate(route, { replace: false });
    }
  };

  const handleLogout = () => {
    clearStorage();
    redirectWindow(routes.root);
  };

  const setNavItems = () => {
    const navItems = [];

    allowed_modules.includes(routes.alliances.root) &&
      navItems.push({ key: routes.alliances.root, label: 'Alianzas' });

    allowed_modules.includes(routes.admins.root) &&
      navItems.push({ key: routes.admins.root, label: 'Administradores' });

    allowed_modules.includes(routes.courses.root) &&
      navItems.push({ key: routes.courses.root, label: 'Cursos' });

    allowed_modules.includes(routes.students.root) &&
      navItems.push({ key: routes.students.root, label: 'Estudiantes' });

    allowed_modules.includes(routes.instructors.root) &&
      navItems.push({ key: routes.instructors.root, label: 'Instructores' });

    if(!isSmallWindow) {
      navItems.push({ key: 'settings', label:
          <span className={'grid content-center text-center h-10 w-10 bg-secondary rounded-full p-2 border ' +
            'border-secondaryLight cursor-pointer hover:border-complementary hover:text-snow'}>
          {userNameAcronym}
        </span>,
        children: [
          ...isStudent ? [{ key: routes.profile, label: 'Perfil' }] : [],
          { key: 'logout', label: 'Cerrar sesión' }
        ],
      });
    }

    return navItems;
  };

  return {
    drawerVisible,
    isSmallWindow,
    currentRoute,
    userName,
    isStudent,
    setDrawerVisible,
    handleRouteSelector,
    setNavItems,
  }
}

export default useNavbar;