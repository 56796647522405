// Libraries
import React from "react";
import {LockOutlined, MailOutlined} from '@ant-design/icons';
import useRegister from "./useRegister";
import {baseRoute} from "../accountHelpers";

// Components
import InputComponent from "../../subComponents/InputComponent";
import FormItemComponent from "../../subComponents/FormItemComponent";
import AccountView from "../AccountView";

// Utils and Helpers
import {isEmpty} from "../../../utils/generalUtils";


const Register = () => {
  const {form, passwordConfirm, isSamePassword, handleSubmit} = useRegister();

  return (
    <AccountView title={'Crea tu cuenta'}
                 buttonTitle={'Crear cuenta'}
                 form={form}
                 content={
                   <>
                     <FormItemComponent name={'email'}
                                        required={true}
                                        newRules={[
                                          { type: 'email', message: 'No es un correo válido' }
                                        ]}
                                        className={'mx-0'}
                                        child={<InputComponent type={'email'}
                                                               placeHolder={'Correo'}
                                                               prefix={<MailOutlined />} />} />
                     <FormItemComponent name={'password'}
                                        required={true}
                                        className={'mx-0'}
                                        child={<InputComponent type={'password'}
                                                               placeHolder={'Contraseña'}
                                                               prefix={<LockOutlined />} />} />
                     <FormItemComponent name={'passwordConfirm'}
                                        required={true}
                                        className={'mx-0'}
                                        child={<InputComponent type={'password'}
                                                               placeHolder={'Confirmación de contraseña'}
                                                               prefix={<LockOutlined />} />} />
                     {!isSamePassword && !isEmpty(passwordConfirm) &&
                       <p className={'w-full text-right text-complementary m-0 -mt-3'}>Las contraseñas no coinciden.</p>
                     }
                   </>
                 }
                 footerContent={
                   <>
                     {'¿Ya tienes una cuenta? '}
                     <a href={baseRoute.login}
                        className={'text-secondaryLight font-bold hover:underline'}>
                       Inicia sesión
                     </a>
                   </>
                 }
                 onFinish={handleSubmit} />
  );
}

export default Register;