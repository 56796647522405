import request from './requestWrapper';

// Types
import {PaginationType} from "../types/SharedTypes";


function getAll(pagination: PaginationType) {
  return request({
    url: 'Alliance/GetAll',
    method: 'POST',
    data: pagination
  });
}
function add(alliance: FormData) {
  return request({
    url: 'Alliance/Add',
    method: 'POST',
    data: alliance
  });
}
function update(alliance: FormData) {
  return request({
    url: 'Alliance/Update',
    method: 'PUT',
    data: alliance
  });
}

export default {
  getAll, add, update
}