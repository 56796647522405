// Constants
import {studentTypes} from '../types';
import {StudentReducerType} from "../../../types/StudentTypes";

const initialState = {
  alliances: [],
  studentResponse: {
    page: 1, pageSize: 10, rowCount: 0, collection: []
  },
  studentProfile: {}
};

const studentReducer = (state = initialState, action: StudentReducerType) => {
  switch (action.type) {
    case studentTypes.RESET_STUDENTS:
      return {
        ...state,
        studentResponse: {
          page: 1, pageSize: 10, rowCount: 0, collection: []
        },
      };
    case studentTypes.GET_STUDENT_PARAMS:
      return {
        ...state,
        alliances: action.alliances
      };
    case studentTypes.GET_STUDENT_PROFILE:
      return {
        ...state,
        studentProfile: action.studentProfile
      };
    case studentTypes.GET_ALL_STUDENTS:
      return {
        ...state,
        studentResponse: action.studentResponse
      };
    default:
      return state;
  }
};

export default studentReducer;
