import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Tooltip} from "antd";
import {EditOutlined, OrderedListOutlined, TeamOutlined} from "@ant-design/icons";

// Store
import * as courseSelectors from '../../store/redux/selectors/courseSelectors';
import * as courseAllianceSelectors from '../../store/redux/selectors/courseAllianceSelectors';
import {useAppDispatch} from "../../store/store";
import {getAll} from "../../store/redux/actions/courseActions";
import {getAllCourses} from "../../store/redux/actions/courseAllianceActions";

// Types
import {FiltersPaginationType} from "../../types/SharedTypes";
import {CourseType} from "../../types/CourseTypes";

// Utils
import {getLocalStorageItem, redirectWindow, ROLE_NAMES, useScreenSizeValidator} from "../../utils/generalUtils";
import {routes} from "../../utils/routes";


type PropsType = {
  canCreate: boolean;
  canUpdate: boolean;
  canRemove: boolean;
};
const useCourses = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const [pagination, setPagination] = useState<FiltersPaginationType>({
    paginationVm: {
      page: 1,
      pageSize: 12,
    },
    filterVm: {}
  });
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [sectionsModalVisible, setSectionsModalVisible] = useState(false);
  const [instructorsModalVisible, setInstructorModalVisible] = useState(false);
  const [courseToEdit, setCourseToEdit] = useState<CourseType | undefined>(undefined);
  const isStudent = getLocalStorageItem('render_role') === ROLE_NAMES.STUDENT;
  const isAlliance = getLocalStorageItem('render_role') === ROLE_NAMES.ALLIANCE;
  const courseResponse = useSelector(courseSelectors.allCoursesSelector)!;
  const courseAllianceResponse = useSelector(courseAllianceSelectors.allCourseAllianceSelector)!;
  const response = isStudent || isAlliance ? courseAllianceResponse : courseResponse;
  const isSmallScreen = useScreenSizeValidator("only screen and (max-width : 575px)");

  useEffect(() => {
    dispatch(isStudent || isAlliance ? getAllCourses(pagination) : getAll(pagination));
  }, []);

  const handleCourseFormModal = (visible: boolean, course?: CourseType) => {
    setCourseToEdit(course);
    setFormModalVisible(visible);
  }

  const handleCourseSectionsModal = (visible: boolean, course?: CourseType) => {
    setCourseToEdit(course);
    setSectionsModalVisible(visible);
  }

  const handleCourseInstructorsModal = (visible: boolean, course?: CourseType) => {
    setCourseToEdit(course);
    setInstructorModalVisible(visible);
  }

  const handleCourseDetail = (courseId: string) => {
    if(isStudent){
      redirectWindow(routes.courses.courseDetail.replace(':courseId', courseId));
    }
  }

  const getCardActions = (course: CourseType) => {
    let actions = [];

    if (props.canUpdate) {
      actions.push(
        <Tooltip title={'Editar curso'} key={"edit"}>
          <span onClick={() => handleCourseFormModal(true, course)}>
            <EditOutlined className={'[&>svg]:fill-secondaryLight'} />
          </span>
        </Tooltip>
      );
    }
    if (!isStudent) {
      actions.push(
        <Tooltip title={'Administrar secciones'} key={"sections"}>
          <span onClick={() => handleCourseSectionsModal(true, course)}>
            <OrderedListOutlined className={'[&>svg]:fill-secondaryLight'} /> {isAlliance && 'Secciones'}
          </span>
        </Tooltip>
      );
      actions.push(
        <Tooltip title={'Administrar instructores'} key={"instructors"}>
          <span onClick={() => handleCourseInstructorsModal(true, course)}>
            <TeamOutlined className={'[&>svg]:fill-secondaryLight'} /> {isAlliance && 'Instructores'}
          </span>
        </Tooltip>
      );
    }

    return actions;
  }

  return {
    pagination,
    formModalVisible,
    sectionsModalVisible,
    instructorsModalVisible,
    courseToEdit,
    isStudent,
    response,
    isSmallScreen,
    setPagination,
    handleCourseFormModal,
    handleCourseSectionsModal,
    handleCourseInstructorsModal,
    handleCourseDetail,
    getCardActions,
  }
}

export default useCourses;
