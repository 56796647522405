// Libraries
import {Action, Dispatch} from 'redux';

// Constants
import {generalTypes} from '../types';

export const setLoading = (loading: boolean) => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: generalTypes.IS_LOADING,
    loading,
  });
};

export const setCurrentRoute = (currentRoute: string) => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: generalTypes.SET_CURRENT_ROUTE,
    currentRoute,
  });
};
