// Libraries
import React from "react";
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import useLogin from "./useLogin";
import {baseRoute} from "../accountHelpers";

// Components
import InputComponent from "../../subComponents/InputComponent";
import FormItemComponent from "../../subComponents/FormItemComponent";
import AccountView from "../AccountView";


const Login = () => {
  const {form, handleSubmit} = useLogin();

  return (
    <AccountView title={'Inicia sesión'}
                 buttonTitle={'Ingresar'}
                 form={form}
                 content={
                  <>
                    <FormItemComponent name={'email'} required={true}
                                       className={'mx-0'}
                                       child={<InputComponent type={'email'} placeHolder={'Correo'} prefix={<UserOutlined />} />} />
                    <FormItemComponent name={'password'} required={true}
                                       className={'mx-0'}
                                       child={<InputComponent type={'password'} placeHolder={'Contraseña'}
                                                              prefix={<LockOutlined />} />} />
                    <p className={'text-right w-full'}>
                      <a href={baseRoute.forgotPassword}
                         className={'text-secondaryLight font-semibold hover:underline'}>
                        ¿Olvidaste tu contraseña?
                      </a>
                    </p>
                  </>
                 }
                 footerContent={
                   <>
                     {'¿No tienes una cuenta? '}
                     <a href={baseRoute.register}
                        className={'text-secondaryLight font-bold hover:underline'}>
                       Regístrate aquí
                     </a>
                   </>
                 }
                 onFinish={handleSubmit} />
  );
}

export default Login;