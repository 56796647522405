// Libraries
import React from 'react';
import {Col, Form, FormInstance, Divider, Card, Space, Button} from 'antd';
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";
import useExamSection from "./useExamSection";

// Components
import FormItemComponent from '../../../subComponents/FormItemComponent';
import InputComponent from '../../../subComponents/InputComponent';
import SelectComponent from "../../../subComponents/SelectComponent";
import ButtonComponent from "../../../subComponents/ButtonComponent";

// Type
import {ResourceType} from "../../../../types/ResourceTypes";


type PropsType = {
  form: FormInstance<any>;
  resourceToEdit?: ResourceType;
};
const ExamSection = (props: PropsType) => {
  const {
    componentKey, questionList, handleAddQuestion, handleAnswerOption, handleRemoveQuestion, handleChangeCorrectAnswer
  } = useExamSection(props);

  return (
    <Col md={24} xs={24} className={'mb-10'} key={componentKey}>
      <Divider className={'before:border-smokeDark after:border-smokeDark text-black05'}>
        Cuestionario
      </Divider>

      <Form.List name={['exam', 'questions']}>
        {() =>
          <Space direction={'vertical'} size={'middle'} className={'w-full'}>
            {questionList.map((question) => (
              <Card size={"small"}
                    className={'bg-snow border-[1px] border-smokeExtraDark'}
                    title={`Pregunta ${question.name + 1}`}
                    key={question.key}
                    extra={<CloseOutlined onClick={() => handleRemoveQuestion(question.key)} />}
              >
                <FormItemComponent name={[question.name, 'content']}
                                   required={true}
                                   child={<InputComponent type={'textarea'}
                                                          placeHolder={'Escribe tu pregunta aquí.'} />}
                />

                <Form.Item label={"Opciones de respuesta"}>
                  <Form.List name={[question.name, 'answers']}>
                    {() =>
                      <Col className={'grid grid-cols-2 gap-2 gap-y-0'}>
                        {question.answerOptions.map((option) =>
                            <Col key={option.id} className={'grid grid-cols-12 col-span-2 sm:col-span-1'}>
                              <FormItemComponent name={[option.id, 'content']}
                                                 className={`${question.answerOptions.length > 2 ? 'col-span-11' : 'col-span-12'}`}
                                                 required={true}
                                                 label={option.name}
                                                 child={<InputComponent />}
                              />

                              {question.answerOptions.length > 2 &&
                                <Col className={'col-span-1 flex'}>
                                  <CloseOutlined className={'m-auto cursor-pointer hover:text-complementary'}
                                                 title={'Eliminar opción de respuesta'}
                                                 onClick={() => handleAnswerOption(false, question.name, option.id)} />
                                </Col>
                              }
                            </Col>
                        )}

                        {question.answerOptions.length < 4 &&
                          <Col className={'col-span-2 sm:col-span-1 flex'}>
                            <Button type={'dashed'}
                                    icon={<PlusOutlined className={'text-complementary'} />}
                                    className={'m-auto w-full border-complementary text-complementary'}
                                    onClick={() => handleAnswerOption(true, question.key)}>
                              Agregar opción de respuesta
                            </Button>
                          </Col>
                        }

                        <FormItemComponent name={['correctOption']}
                                           required={true}
                                           className={'col-span-2 mt-4'}
                                           label={'La opción correcta es:'}
                                           child={<SelectComponent idIdentifier={'id'}
                                                                   valueIdentifier={'name'}
                                                                   list={question.answerOptions}
                                                                   className={'w-1/2'}
                                                                   onChange={(optionId) => handleChangeCorrectAnswer(optionId, question.name)} />}
                        />
                      </Col>
                    }
                  </Form.List>
                </Form.Item>
              </Card>
            ))}

            <ButtonComponent text={'Nueva pregunta'}
                             className={'w-full hover:text-primary hover:border-primary'}
                             icon={<PlusOutlined />}
                             onClick={handleAddQuestion} />
          </Space>
        }
      </Form.List>
    </Col>
  );
}

export default ExamSection;