// Libraries
import React from 'react';
import {Row, Col, Form, Modal, Switch, Image} from 'antd';
import baseURL from "../../../api/baseURL";
import blankProfileImage from '../../../assets/images/blank-profile-image.jpg';
import useGeekFormModal from "./useGeekFormModal";

// Types
import {GeekType} from "../../../types/GeekTypes";

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';
import InputComponent from '../../subComponents/InputComponent';


type GeekFormModalType = {
  geekToEdit?: GeekType;
  onToggleModal: (visible: boolean) => void;
};
const GeekFormModal = (props: GeekFormModalType) => {
  const {geekToEdit, onToggleModal} = props;
  const {form, isEditing, handleSubmit} = useGeekFormModal(props);

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} administrador`} width={isEditing ? 700 : 500}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'}
                              text={'Cerrar'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'}
                              text={`${isEditing ? 'Editar' : 'Crear'}`}
                              className={'ant-btn-primary'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"} className={'pt-4'} form={form} onFinish={handleSubmit} initialValues={{}}>
        <Row gutter={[8, 0]}>
          <Col md={isEditing ? 12 : 0} xs={isEditing ? 24 : 0} className={`${!isEditing && 'flex'}`}>
            <Col md={24} xs={24} className={`text-center ${!isEditing && 'm-auto'}`}>
              {isEditing &&
                <Image className={'h-40'}
                       src={geekToEdit?.imageUrl ? baseURL! + geekToEdit?.imageUrl : blankProfileImage} />
              }
            </Col>
          </Col>
          <Col md={isEditing ? 12 : 24} xs={24}>
            {isEditing &&
              <Col md={24} xs={24}>
                <FormItemComponent name={'isActive'}
                                   required={false}
                                   valuePropName={'checked'}
                                   child={<Switch checkedChildren={'Activo'}
                                                  unCheckedChildren={'Inactivo'}
                                                  className={'w-fit'} />}
                />
              </Col>
            }
            <Col md={24} xs={24}>
              <FormItemComponent name={'name'}
                                 label={'Nombre'}
                                 required={true} child={<InputComponent placeHolder={'Nombre'} />}
              />
            </Col>
            <Col md={24} xs={24}>
              <FormItemComponent name={'email'}
                                 required={true}
                                 label={'Correo'}
                                 newRules={[
                                   { type: 'email', message: 'No es un correo válido' }
                                 ]}
                                 child={<InputComponent placeHolder={'Correo'}
                                                        type={'email'}
                                                        readOnly={isEditing} />}
              />
            </Col>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default GeekFormModal;