// Libraries
import React, {useEffect, useState} from 'react';
import {Form} from 'antd';
import {BreadcrumbItemType, BreadcrumbSeparatorType} from "antd/es/breadcrumb/Breadcrumb";
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from '../../../store/store';
import {getById} from "../../../store/redux/actions/courseActions";
import {add, remove} from "../../../store/redux/actions/courseInstructorActions";
import * as courseSelectors from "../../../store/redux/selectors/courseSelectors";

// Types
import {CourseType} from "../../../types/CourseTypes";
import {CourseInstructorType} from "../../../types/CourseInstructorTypes";


type PropsType = {
  courseToEdit?: CourseType;
  onToggleModal: (visible: boolean) => void;
};
const useInstructorsModal = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const [breadcrumbItems, setBreadcrumbItems] =
    useState<Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] | undefined>(
      [{
        title: <span className={'cursor-pointer'}>Información del curso</span>,
        onClick: () => handleSteps(1)
      }]
    );
  const [modalKey, setModalKey] = useState(1);
  const [instructorForm] = Form.useForm();
  const {courseToEdit, onToggleModal} = props;
  const courseDetail = useSelector(courseSelectors.courseDetailSelector)!;

  useEffect(() => {
    dispatch(getById(courseToEdit?.courseId!));
  }, [modalKey]);

  const handleSteps = (newStep: number, stepTitle: string = '') => {
    const newBreadcrumbItems = breadcrumbItems;
    const currentStep = newBreadcrumbItems!.length;
    const isNewStep = currentStep < newStep;

    if (isNewStep) {
      instructorForm.resetFields();
      newBreadcrumbItems!.push({
        title: <span className={'cursor-pointer'}>{stepTitle}</span>,
        onClick: () => handleSteps(newStep)
      });
    } else {
      newBreadcrumbItems!.length = newStep;
    }
    setBreadcrumbItems(newBreadcrumbItems);
    setModalKey(modalKey + 1);
  };

  const handleSubmit = () => {
    let values: CourseInstructorType = instructorForm.getFieldsValue();
    values.courseId = courseDetail.course.courseId;

    dispatch(add(values));

    handleSteps(breadcrumbItems!.length - 1);
  }

  const handleRemoveInstructor = (courseInstructor: CourseInstructorType) => {
    dispatch(remove(courseInstructor));
  }

  return {
    breadcrumbItems,
    modalKey,
    courseDetail,
    instructorForm,
    handleSteps,
    handleSubmit,
    handleRemoveInstructor,
  }
}

export default useInstructorsModal;