import React, {useEffect, useState} from "react";
import {Divider, Popconfirm, Space} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from "../../store/store";
import {getAllByAllianceId, getParams, remove} from "../../store/redux/actions/studentActions";
import * as studentSelectors from '../../store/redux/selectors/studentSelectors';

// Types
import {FiltersPaginationType} from "../../types/SharedTypes";
import {StudentType} from "../../types/StudentTypes";
import {getLocalStorageItem, ROLE_NAMES, useScreenSizeValidator} from "../../utils/generalUtils";


const useStudents = () => {
  const dispatch = useAppDispatch();
  const [pagination, setPagination] = useState<FiltersPaginationType>({
    paginationVm: {
      page: 1,
      pageSize: 10,
    },
    filterVm: {}
  });
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [studentToEdit, setStudentToEdit] = useState<StudentType | undefined>(undefined);
  const [selectedAllianceId, setSelectedAllianceId] = useState<string | null>(null);
  const { page, pageSize, rowCount, collection } = useSelector(studentSelectors.allStudentsSelector)!;
  const alliances = useSelector(studentSelectors.allianceParamsSelector)!;
  const isAlliance = getLocalStorageItem('render_role') === ROLE_NAMES.ALLIANCE;
  const isSmallWindow = useScreenSizeValidator('(max-width: 767px)');

  useEffect(() => {
    if (isAlliance)
      dispatch(getAllByAllianceId(pagination));
    else
      dispatch(getParams());
  }, []);

  useEffect(() => {
    selectedAllianceId && dispatch(getAllByAllianceId(pagination, selectedAllianceId))
  }, [selectedAllianceId]);

  const handleStudentModal = (visible: boolean, student?: StudentType) => {
    setStudentToEdit(student);
    setFormModalVisible(visible);
  }

  const handleActionButtons = (student: StudentType) => {
    return (
      <Space split={isSmallWindow && <Divider type={'vertical'} />} size={isSmallWindow ? 'large' : 'small'}>
        <span className={'cursor-pointer hover:text-primary'}
              title={'Editar'}
              onClick={() => handleStudentModal(true, student)}>
          <EditOutlined /> {isSmallWindow && 'Editar'}
        </span>
        {!isAlliance &&
          <Popconfirm title={'¿Deseas eliminar este estudiante?'} okText={'Confirmar'} cancelText={'Cancelar'}
                      onConfirm={() => dispatch(remove(student))}>
            <span className={'cursor-pointer hover:text-complementary'} title={'Eliminar'}>
              <DeleteOutlined /> {isSmallWindow && 'Eliminar'}
            </span>
          </Popconfirm>
        }
      </Space>
    )
  };

  return {
    formModalVisible,
    studentToEdit,
    selectedAllianceId,
    page,
    pageSize,
    rowCount,
    collection,
    alliances,
    isAlliance,
    isSmallWindow,
    setSelectedAllianceId,
    handleStudentModal,
    handleActionButtons
  }
}

export default useStudents;
