import React, {useEffect, useState} from "react";
import {ResourceType} from "../../../../types/ResourceTypes";
import {Form} from "antd";
import {useSelector} from "react-redux";

// Components
import {WARNING_MODAL} from "../../../subComponents/responseModals";

// Store
import {useAppDispatch} from "../../../../store/store";
import {completeExam, resetExamScore} from "../../../../store/redux/actions/courseStudentResourceActions";
import * as courseStudentResourceSelectors from "../../../../store/redux/selectors/courseStudentResourceSelectors";


type PropsType = {
  resourceDetail: ResourceType;
}
const useExam = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const [showResult, setShowResult] = useState(false);
  const [examForm] = Form.useForm();
  const {resourceDetail} = props;
  const [examTimer, setExamTimer] = useState('00:00');
  const examScoreResponse = useSelector(courseStudentResourceSelectors.examScoreSelector)!;

  useEffect(() => {
    dispatch(resetExamScore());
    examForm.resetFields();

    const examSeconds = resourceDetail.exam.durationInMinutes * 60;
    let timer = examSeconds, minutes, seconds;
    const interval = setInterval(function () {
      minutes = Math.floor(timer / 60);
      seconds = timer % 60;

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setExamTimer(minutes + ":" + seconds);

      if (--timer < 0) {
        timer = examSeconds;
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if(examScoreResponse.graded) {
      setShowResult(true);
    }
  }, [examScoreResponse]);

  const checkIsCorrect = (questionId: string) => {
    return examScoreResponse.result.find(result => result.questionId === questionId)!.isCorrect;
  }

  const handleFinish = () => {
    examForm.validateFields()
      .then(values => {
        const newExamToSend = resourceDetail.exam;
        const questions = newExamToSend.questions;

        questions.map(question => {
          const correctOption = values[question.questionId].correctOption;
          question.answers.map(answer => {
            answer.isCorrectByUser = answer.answerId === correctOption;
          })
        });

        dispatch(completeExam(newExamToSend));
      }).catch(() => {
        WARNING_MODAL('Aún hay preguntas sin responder. Por favor, revisa e intenta de nuevo.')
    });
  }

  return {
    showResult,
    examForm,
    examTimer,
    examScoreResponse,
    setShowResult,
    checkIsCorrect,
    handleFinish,
  }
}

export default useExam;