/* --------------- General ---------------*/
export const generalTypes = {
  IS_LOADING: 'IS_LOADING',
  SET_CURRENT_ROUTE: 'SET_CURRENT_ROUTE',
};

/* --------------- Alliance ---------------*/
export const allianceTypes = {
  GET_ALL_ALLIANCES: 'GET_ALL_ALLIANCES',
};

/* --------------- Geek ---------------*/
export const geekTypes = {
  GET_ALL_GEEKS: 'GET_ALL_GEEKS',
};

/* --------------- Course ---------------*/
export const courseTypes = {
  RESET_COURSE_DETAIL: 'RESET_COURSE_DETAIL',
  GET_COURSE_DETAIL: 'GET_COURSE_DETAIL',
  GET_ALL_COURSES: 'GET_ALL_COURSES',
};

/* --------------- Course Alliance ---------------*/
export const courseAllianceTypes = {
  GET_COURSE_ALLIANCE_PARAMS: 'GET_COURSE_ALLIANCE_PARAMS',
  GET_ALL_COURSE_ALLIANCE: 'GET_ALL_COURSE_ALLIANCE',
};

/* --------------- Course Section ---------------*/
export const courseSectionTypes = {
  GET_ALL_COURSE_SECTIONS: 'GET_ALL_COURSE_SECTIONS',
};

/* --------------- Course Student ---------------*/
export const courseStudentTypes = {
  GET_ALL_COURSE_STUDENT: 'GET_ALL_COURSE_STUDENT',
};

/* --------------- Course Student Resource ---------------*/
export const courseStudentResourceTypes = {
  RESET_EXAM_SCORE: 'RESET_EXAM_SCORE',
  GET_EXAM_SCORE: 'GET_EXAM_SCORE',
};

/* --------------- Resource ---------------*/
export const resourceTypes = {
  GET_RESOURCE_PARAMS: 'GET_RESOURCE_PARAMS',
  GET_ALL_RESOURCES: 'GET_ALL_RESOURCES',
};
/* --------------- Student ---------------*/
export const studentTypes = {
  RESET_STUDENTS: 'RESET_STUDENTS',
  GET_STUDENT_PARAMS: 'GET_STUDENT_PARAMS',
  GET_STUDENT_PROFILE: 'GET_STUDENT_PROFILE',
  GET_ALL_STUDENTS: 'GET_ALL_STUDENTS',
};
/* --------------- Instructor ---------------*/
export const instructorTypes = {
  RESET_INSTRUCTORS: 'RESET_INSTRUCTORS',
  GET_INSTRUCTOR_PROFILE: 'GET_INSTRUCTOR_PROFILE',
  GET_ALL_INSTRUCTORS: 'GET_ALL_INSTRUCTORS',
};