// Libraries
import React from 'react';
import {Row, Col, Form, Select, FormInstance} from 'antd';
import useCourseFormModal from "./useCourseFormModal";

// Components
import FormItemComponent from '../../../subComponents/FormItemComponent';

// Types
import {GenericParamsType} from "../../../../types/SharedTypes";


type PropsType = {
  form: FormInstance<any>;
};
const CourseFormModal = (props: PropsType) => {
  const {params} = useCourseFormModal();
  const {form} = props;

  return (
    <Form layout={"vertical"} className={'pt-4'} form={form} initialValues={{}}>
      <Row gutter={[8, 0]} className={'pt-4'}>
        <Col md={24} xs={24}>
          <FormItemComponent name={'courseId'}
                             required={true}
                             label={'Nombre del curso'}
                             child={<Select showSearch
                                            placeholder={"Buscar por nombre"}
                                            optionFilterProp={"children"}
                                            className={'w-full h-9 [&>div]:h-9 [&>div>span>input]:ring-0'}
                                            filterOption={(input, option) =>
                                              (option?.label ?? '').includes(input)
                                            }
                                            options={params.map((course: GenericParamsType) =>
                                              ({
                                                value: course.customId,
                                                label: course.name,
                                              })
                                            )}
                             />}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default CourseFormModal;