import {StoreType} from "../../../types/SharedTypes";

export const allianceParamsSelector = (state: StoreType) => {
  return state.studentReducer.alliances;
}

export const allStudentsSelector = (state: StoreType) => {
  return state.studentReducer.studentResponse;
}

export const studentProfileSelector = (state: StoreType) => {
  return state.studentReducer.studentProfile;
}
