// Libraries
import React, {useEffect} from 'react';
import {Form} from 'antd';

// Store
import {useAppDispatch} from '../../../store/store';
import { add, update } from '../../../store/redux/actions/instructorActions';

// Types
import {InstructorType} from "../../../types/InstructorTypes";


type PropsType = {
  instructorToEdit?: InstructorType;
  onToggleModal: (visible: boolean) => void;
};
const useInstructorsFormModal = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const {instructorToEdit, onToggleModal} = props;
  const [form] = Form.useForm();
  const isEditing = instructorToEdit !== undefined;

  useEffect(() => {
    if(isEditing) {
      form.setFieldsValue({
        isActive: instructorToEdit?.isActive,
        name: instructorToEdit?.name,
        email: instructorToEdit?.email,
      });
    }
  }, []);

  const handleSubmit = (instructor: InstructorType) => {
    if(isEditing) {
      instructor.instructorId = instructorToEdit?.instructorId;
    }

    isEditing ? dispatch(update(instructor)) : dispatch(add(instructor));
    onToggleModal(false);
  }

  return {
    form,
    isEditing,
    handleSubmit,
  }
}

export default useInstructorsFormModal;