// Libraries
import React from "react";
import {Form} from "antd";
import {useParams} from "react-router-dom";
import {isAdminView} from "../accountHelpers";

// Store
import {useAppDispatch} from "../../../store/store";
import {resetPassword, resetPasswordSpecialUser} from "../../../store/redux/actions/accountActions";

// Types
import {AccountType} from "../../../types/AccountTypes";


const useResetPassword = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const password = Form.useWatch('password', form);
  const passwordConfirm = Form.useWatch('passwordConfirm', form);
  const isSamePassword = password == passwordConfirm;
  const {userId, code} = useParams();

  const handleSubmit = (values: AccountType) => {
    values.code = code;
    values.userId = userId;
    if (isAdminView) {
      dispatch(resetPasswordSpecialUser(values));
    } else {
      dispatch(resetPassword(values));
    }
  };

  return {
    form,
    passwordConfirm,
    isSamePassword,
    handleSubmit,
  }
}

export default useResetPassword;