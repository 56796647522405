// Libraries
import React from 'react';
import {Row, Col, Form, Modal, Image, DatePicker} from 'antd';
import baseURL from "../../../api/baseURL";
import useCourseFormModal from "./useCourseFormModal";

// Types
import {CourseType} from "../../../types/CourseTypes";

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';
import InputComponent from '../../subComponents/InputComponent';
import UploadComponent from "../../subComponents/UploadComponent";

// Utils
import {DATE_FORMAT} from "../../../utils/generalUtils";


type CourseFormModalType = {
  courseToEdit?: CourseType;
  onToggleModal: (visible: boolean) => void;
};
const CourseFormModal = (props: CourseFormModalType) => {
  const {courseToEdit, onToggleModal} = props;
  const {form, isEditing, file, acceptFormat, setFile, handleSubmit} = useCourseFormModal(props);

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} curso`} width={700}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Crear'}`} className={'ant-btn-primary'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"} className={'pt-4'} form={form} onFinish={handleSubmit} initialValues={{}}>
        <Row gutter={[8, 0]}>
          <Col md={12} xs={24} className={`${!isEditing && 'flex'}`}>
            <Col md={24} xs={24} className={`text-center ${!isEditing && 'm-auto'}`}>
              {isEditing && courseToEdit?.imageUrl &&
                <Image className={'h-44'}
                       src={baseURL! + courseToEdit?.imageUrl} />
              }
              <UploadComponent acceptFormat={acceptFormat}
                               uploadText={`${isEditing && courseToEdit?.imageUrl ? 'Cambiar' : 'Cargar'} imagen del curso`}
                               file={file as File} setFile={setFile} />
            </Col>
          </Col>
          <Col md={12} xs={24}>
            <Col md={24} xs={24}>
              <FormItemComponent name={'name'} required={true} label={'Nombre'}
                                 child={<InputComponent placeHolder={'Nombre'} />}
              />
            </Col>
            <Col md={24} xs={24}>
              <FormItemComponent name={'description'} required={true} label={'Descripción'}
                                 child={<InputComponent type={'textarea'} />}
              />
            </Col>
            <Col md={24} xs={24}>
              <FormItemComponent name={'releaseDate'} required={true} label={'Fecha de lanzamiento'}
                                 child={<DatePicker placeholder={'Selecciona una fecha'}
                                                    className={'w-full'}
                                                    allowClear={false} format={DATE_FORMAT} />}
              />
            </Col>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CourseFormModal;