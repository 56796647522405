// Libraries
import React, {useState} from 'react';
import {Col, message, Modal, Upload, UploadFile, UploadProps} from 'antd';
import {PictureOutlined} from "@ant-design/icons";
import {RcFile} from "antd/es/upload";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });


type UploadComponentType = {
  acceptFormat: string;
  uploadText: string;
  file?: File;
  setFile: (file: File) => void;
};
const UploadComponent = (props: UploadComponentType) => {
  const { acceptFormat, uploadText, file, setFile } = props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const getUploadProps = () => {
    const uploadProps: UploadProps<any> = {
      multiple: false,
      listType: "picture-card",
      accept: acceptFormat,
      onPreview: handlePreview,
      onRemove: () => setFile({ name: '' } as File),
      customRequest({ file, onSuccess, onError }: any) {
        let error = false;

        if(file.type === '' || !acceptFormat.includes(file.type)){
          message.error({ content: `El archivo "${file.name}" no está permitido.` });
          error = true;
        } else if(file.size/1024/1024 > 5) {
          message.error({ content: `El archivo "${file.name}" sobrepasa el tamaño permitido (5MB).` });
          error = true;
        }

        if (error) {
          setTimeout(() => { onError('error'); }, 0);
          return;
        } else {
          setTimeout(() => { onSuccess('ok'); }, 0);
          setFile(file);
        }
      },
    };

    return uploadProps;
  }

  return (
    <>
      <Upload className={'w-full [&>div>div]:w-full'} {...getUploadProps()}>
        {file?.name === '' && <Col><PictureOutlined /> {uploadText}</Col>}
      </Upload>
      <Modal open={previewOpen} footer={null} closable={false} width={400} onCancel={() => setPreviewOpen(false)}>
        <img alt={""} className={'w-full'} src={previewImage} />
      </Modal>
    </>
  );
}

export default UploadComponent;