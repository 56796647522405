// Libraries
import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {CollapseProps, Space, Tree} from "antd";
import { DataNode } from 'antd/es/tree';

// Store
import {useAppDispatch} from '../../../../store/store';
import {getById} from "../../../../store/redux/actions/courseActions";
import * as courseSelectors from "../../../../store/redux/selectors/courseSelectors";

// Types
import {CourseType} from "../../../../types/CourseTypes";


type PropsType = {
  courseDetail?: CourseType;
};
const useCourseDetailModal = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const {courseDetail} = props;
  const course = useSelector(courseSelectors.courseDetailSelector)!;
  const treeData: DataNode[] = course.courseSections.map(section => {
   return {
     title: section.name,
     key: section.courseSectionId,
     selectable: false,
     children: section.resources.map(resource => {
       return {
         title: resource.name,
         key: resource.resourceId,
       }
     })
   }
  });

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: `Instructores (${course.courseInstructors.length})`,
      children:
        <Space direction={'vertical'}>
          {course.courseInstructors.map((instructor, index) =>
            <p key={index}>
              {`${index + 1}. ${instructor.instructor.name}`} <b>{`(${instructor.instructor.email})`}</b>
            </p>
          )}
        </Space>,
      showArrow: false,
    },
    {
      key: '2',
      label: `Secciones (${course.courseSections.length})`,
      children:
        <Tree treeData={treeData}
        />,
      showArrow: false,
    },
  ];

  useEffect(() => {
    dispatch(getById(courseDetail!.courseId));
  }, []);

  return {
    course,
    items,
  }
}

export default useCourseDetailModal;