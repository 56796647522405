// Constants
import {allianceTypes} from '../types';
import {AllianceReducerType} from "../../../types/AllianceTypes";

const initialState = {
  allianceResponse: {
    page: 1, pageSize: 12, rowCount: 0, collection: []
  }
};

const allianceReducer = (state = initialState, action: AllianceReducerType) => {
  switch (action.type) {
    case allianceTypes.GET_ALL_ALLIANCES:
      return {
        ...state,
        allianceResponse: action.allianceResponse
      };
    default:
      return state;
  }
};

export default allianceReducer;
