import React from "react";
import {List, Progress} from "antd";
import {RightOutlined} from "@ant-design/icons";
import noImage from '../../../assets/images/no-image.jpg';
import useMyCourses from "./useMyCourses";

// Types
import {CourseStudentType} from "../../../types/CourseStudentTypes";

// Components
import CardComponent from "../../subComponents/CardComponent";
import CoursesList from "../subComponents/CoursesList";

// Utils
import {routes} from "../../../utils/routes";


const MyCourses = () => {
  const {pagination, courseStudentResponse, isSmallScreen, setPagination, handleCourseDetail} = useMyCourses();

  return (
    <CoursesList showRedirectContent={true}
                 showExtraButtons={false}
                 sectionTitle={'MIS CURSOS'}
                 redirectRoute={routes.courses.root}
                 response={courseStudentResponse}
                 pagination={pagination}
                 redirectContent={
                   <>
                     <span className={'group-hover:underline font-semibold group-hover:text-complementary'}>
                       Todos los cursos
                     </span> <RightOutlined className={'group-hover:text-complementary'} />
                   </>
                 }
                 extraButtonsContent={<></>}
                 setPagination={setPagination}
                 renderItem={
                   (course: CourseStudentType) => (
                     <List.Item className={'h-full contents'}
                                onClick={() => handleCourseDetail(course.courseId)}>
                       <span className={'absolute right-5 p-1 z-50 bg-secondaryDark'}>
                         <Progress type={"circle"}
                                   percent={course.progress}
                                   size={35}
                                   strokeColor={'#EA345C'}
                                   trailColor={'#E0E6ED'}
                                   className={'[&>div>span]:text-complementaryLight'} />
                       </span>
                       <CardComponent noImageUrl={noImage}
                                      hoverable={true}
                                      bodyHeight={isSmallScreen ? '140px' : '200px'}
                                      item={course.course}
                                      actions={[]}
                                      description={
                                        <p className={'h-full line-clamp-3 sm:line-clamp-6 text-justify'}>
                                          {course.course!.description}
                                        </p>
                                      } />
                     </List.Item>
                   )
                 } />
  );
}

export default MyCourses;