import {StudentType} from "../types/StudentTypes";

export const tableColumns = (section: string, actionButtons?: (object: any) => any) => {
  switch (section){
    case 'students':
      return [
        {
          title: <div className={'text-center'}>Nombre</div>,
          dataIndex: 'name',
          key: 'name',
          width: '200px',
          ellipsis: true,
          render: (text: string) => <div className={'text-center'}>{text}</div>
        },
        {
          title: <div className={'text-center'}>Correo</div>,
          dataIndex: 'email',
          key: 'email',
          width: '200px',
          ellipsis: true,
          render: (text: string) => <div className={'text-center'}>{text}</div>
        },
        {
          title: <div className={'text-center'}>Activo</div>,
          dataIndex: 'isActive',
          key: 'isActive',
          width: '100px',
          render: (text: boolean) => <div className={'text-center'}>{text ? 'SI' : 'NO'}</div>
        },
        {
          title: <div className={'text-center'}>Acciones</div>,
          dataIndex: 'actions',
          key: 'actions',
          width: '100px',
          render: (value: any, student: StudentType) => <div className={'text-center'}>{actionButtons!(student)}</div>
        },
      ];
    default:
      return [];
  }
};