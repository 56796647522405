// Libraries
import React from "react";
import {Form} from "antd";
import {isAdminView} from "../accountHelpers";

// Store
import {useAppDispatch} from "../../../store/store";
import {register, registerSpecialUser} from "../../../store/redux/actions/accountActions";

// Types
import {AccountType} from "../../../types/AccountTypes";


const useRegister = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const password = Form.useWatch('password', form);
  const passwordConfirm = Form.useWatch('passwordConfirm', form);
  const isSamePassword = password == passwordConfirm;

  const handleSubmit = (values: AccountType) => {
    if (isAdminView) {
      dispatch(registerSpecialUser(values));
    } else {
      dispatch(register(values));
    }
  };

  return {
    form,
    passwordConfirm,
    isSamePassword,
    handleSubmit
  }
}

export default useRegister;