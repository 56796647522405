// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import courseSectionServices from '../../../api/courseSectionServices';
import {courseSectionTypes} from '../types';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Types
import {CourseSectionType} from "../../../types/CourseSectionTypes";


export const getAllByCourseId = (courseId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  dispatch({
    type: courseSectionTypes.GET_ALL_COURSE_SECTIONS,
    courseSectionResponse: []
  });
  courseSectionServices.getAllByCourseId(courseId)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: courseSectionTypes.GET_ALL_COURSE_SECTIONS,
        courseSectionResponse: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const add = (courseSection: CourseSectionType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseSectionServices.add(courseSection)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAllByCourseId(courseSection.courseId));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const update = (courseSection: CourseSectionType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseSectionServices.update(courseSection)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAllByCourseId(courseSection.courseId));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const remove = (courseSection: CourseSectionType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseSectionServices.remove(courseSection)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAllByCourseId(courseSection.courseId));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}