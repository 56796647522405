// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import courseServices from '../../../api/courseServices';
import {courseTypes} from '../types';
import {instructorTypes} from '../types';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Types
import {FiltersPaginationType} from "../../../types/SharedTypes";


export const getById = (courseId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  dispatch({
    type: courseTypes.RESET_COURSE_DETAIL,
  });
  dispatch({
    type: instructorTypes.RESET_INSTRUCTORS,
  });
  courseServices.getById(courseId)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: courseTypes.GET_COURSE_DETAIL,
        courseDetail: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const getAll = (pagination: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseServices.getAll(pagination)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: courseTypes.GET_ALL_COURSES,
        courseResponse: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const add = (course: FormData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseServices.add(course)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ paginationVm: { page: 1, pageSize: 12 } }));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const update = (course: FormData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseServices.update(course)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ paginationVm: { page: 1, pageSize: 12 } }));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}