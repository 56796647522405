import React, {useEffect} from "react";
import type { TabsProps } from 'antd';
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

// Store
import {useAppDispatch} from "../../../store/store";
import {getById} from "../../../store/redux/actions/courseActions";
import {add} from "../../../store/redux/actions/courseStudentActions";
import * as courseSelectors from '../../../store/redux/selectors/courseSelectors';

// Components
import InstructorInfo from "./InstructorInfo";
import CourseInfo from "./CourseInfo";

// Utils
import {redirectWindow, useScreenSizeValidator} from "../../../utils/generalUtils";
import {routes} from "../../../utils/routes";


const useCourseDetail = () => {
  const dispatch = useAppDispatch();
  const courseDetail = useSelector(courseSelectors.courseDetailSelector)!;
  const { courseId } = useParams();
  const isSmallScreen = useScreenSizeValidator("only screen and (max-width : 639px)");
  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Contenido',
      children: <CourseInfo courseDetail={courseDetail} />,
    },
    {
      key: '2',
      label: 'Instructores',
      children: <InstructorInfo instructors={courseDetail.courseInstructors.map(cI => cI.instructor)} />,
    },
  ];

  useEffect(() => {
    dispatch(getById(courseId!));
  }, []);

  const handleJoinCourse = () => {
    dispatch(add(
      { courseId: courseDetail.course.courseId },
      () => redirectWindow(routes.courses.myCourses)
    ));
  }

  return {
    courseDetail,
    tabItems,
    isSmallScreen,
    handleJoinCourse,
  }
}

export default useCourseDetail;