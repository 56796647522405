import request from './requestWrapper';

// Types
import {FiltersPaginationType} from "../types/SharedTypes";


function getById(courseId: string) {
  return request({
    url: 'Course/GetById',
    method: 'GET',
    params: { courseId }
  });
}
function getAll(pagination: FiltersPaginationType) {
  return request({
    url: 'Course/GetAll',
    method: 'POST',
    data: pagination
  });
}
function add(course: FormData) {
  return request({
    url: 'Course/Add',
    method: 'POST',
    data: course
  });
}
function update(course: FormData) {
  return request({
    url: 'Course/Update',
    method: 'PUT',
    data: course
  });
}

export default {
  getById, getAll, add, update
}