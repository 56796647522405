// Libraries
import React from "react";
import {LockOutlined} from '@ant-design/icons';
import useResetPassword from "./useResetPassword";
import {baseRoute} from "../accountHelpers";

// Components
import InputComponent from "../../subComponents/InputComponent";
import FormItemComponent from "../../subComponents/FormItemComponent";
import AccountView from "../AccountView";

// Utils and Helpers
import {isEmpty} from "../../../utils/generalUtils";


const ResetPassword = () => {
  const {form, passwordConfirm, isSamePassword, handleSubmit} = useResetPassword();

  return (
    <AccountView title={'Cambia tu contraseña'}
                 buttonTitle={'Cambiar contraseña'}
                 form={form}
                 content={
                   <>
                     <FormItemComponent name={'password'} required={true}
                                        className={'mx-0'}
                                        child={<InputComponent type={'password'}
                                                               placeHolder={'Contraseña'}
                                                               prefix={<LockOutlined />} />} />
                     <FormItemComponent name={'passwordConfirm'} required={true}
                                        className={'mx-0'}
                                        child={<InputComponent type={'password'}
                                                               placeHolder={'Confirmación de contraseña'}
                                                               prefix={<LockOutlined />} />} />
                     {!isSamePassword && !isEmpty(passwordConfirm) &&
                       <p className={'w-full text-right text-red-400 m-0 -mt-3'}>Las contraseñas no coinciden.</p>
                     }
                   </>
                 }
                 footerContent={
                   <>
                     {'¿Ya recuerdas tu contraseña? '}
                     <a href={baseRoute.login}
                        className={'text-secondaryLight font-bold hover:underline'}>
                       Inicia sesión
                     </a>
                   </>
                 }
                 onFinish={handleSubmit} />
  );
}

export default ResetPassword;