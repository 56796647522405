// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import courseSectionServices from '../../../api/courseStudentServices';
import {courseStudentTypes} from "../types";

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Types
import {FiltersPaginationType} from "../../../types/SharedTypes";
import {CourseStudentType} from "../../../types/CourseStudentTypes";


export const getAll = (pagination: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseSectionServices.getAll(pagination)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: courseStudentTypes.GET_ALL_COURSE_STUDENT,
        courseStudentResponse: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const add = (courseStudent: CourseStudentType, callback: () => void) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseSectionServices.add(courseStudent)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(callback);
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}