// Libraries
import {useMediaQuery} from 'react-responsive';
import dayjs from "dayjs";
import {routes} from "./routes";

export const DATE_FORMAT = "DD/MM/YYYY";
export const DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";

export const ROLE_NAMES = {
  ADMIN: 'Admin',
  ALLIANCE: 'Alliance',
  INSTRUCTOR: 'Instructor',
  STUDENT: 'Student'
}

export const ROLES = [
  { roleName: ROLE_NAMES.ADMIN,
    modules: [
      { route: routes.alliances.root, create: true, update: true, remove: true },
      { route: routes.admins.root, create: true, update: true, remove: true },
      { route: routes.courses.root, create: true, update: true, remove: true },
      { route: routes.students.root, create: true, update: true, remove: true },
      { route: routes.instructors.root, create: true, update: true, remove: true },
    ]
  },
  { roleName: ROLE_NAMES.ALLIANCE,
    modules: [
      { route: routes.courses.root, create: false, update: false, remove: false },
      { route: routes.students.root, create: false, update: false, remove: false },
    ]
  },
  { roleName: ROLE_NAMES.STUDENT,
    modules: [
      { route: routes.courses.root, create: false, update: false, remove: false },
      { route: routes.courses.myCourses, create: false, update: false, remove: false },
      { route: routes.courses.courseDetail, create: false, update: false, remove: false },
      { route: routes.courses.resourceDetail, create: false, update: false, remove: false },
      { route: routes.profile, create: true, update: true, remove: true },
    ]
  },
  { roleName: ROLE_NAMES.INSTRUCTOR,
    modules: [
      { route: routes.profile, create: true, update: true, remove: true },
    ]
  },
];

export const isEmpty = (element: string | object | null | undefined) => {
  return element === undefined || element === 'undefined'
    || element === null || element === 'null' || element === ''
    || (Array.isArray(element) && element.length === 0)
    || (Object.keys(element).length === 0);
};

export const isSignedIn = () => {
  const access_token = getLocalStorageItem('access_token');
  const expires_on = getLocalStorageItem('expires_on');
  const render_role = getLocalStorageItem('render_role');
  const user_id = getLocalStorageItem('user_id');
  const user_name = getLocalStorageItem('user_name');
  const incorrectInfo = isEmpty(access_token) || isEmpty(expires_on) || isEmpty(render_role) || isEmpty(user_id) || isEmpty(user_name);
  const tokenExpired = new Date() > new Date(expires_on!);

  if(incorrectInfo || tokenExpired) clearStorage();

  return !incorrectInfo && !tokenExpired;
};

/* --------------------- Storage ----------------------- */
export const setSessionStorageItem = (fieldName: string, value: string) => {
  sessionStorage.setItem(fieldName, value);
};
export const setLocalStorageItem = (fieldName: string, value: string) => {
  localStorage.setItem(fieldName, value);
};
export const getSessionStorageItem = (fieldName: string) => {
  return sessionStorage.getItem(fieldName);
};
export const getLocalStorageItem = (fieldName: string) => {
  return localStorage.getItem(fieldName);
};
export const clearStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
};

/* --------------------- Window Actions ----------------------- */
export const getWindowInformation = (paramName?: string) => {
  const pathname = window.location.pathname;
  const search = window.location.search;
  const urlParams = new URLSearchParams(search);
  const param = urlParams.get(paramName ?? '');

  return { pathname, param };
};
export const reloadWindow = () => {
  window.location.reload();
};
export const redirectWindow = (pathname: string) => {
  window.location.href = pathname;
};
export const useScreenSizeValidator = (sizeValidator: string) => {
  return useMediaQuery({ query: sizeValidator });
};

/* ----------------------- Currency ------------------------ */
export function currencyFormat(value?: string){
  const text = `${value}`
    .replace('.', ',')
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.');

  return `${text}`;
}
export function currencyToNumber(value: string){
  const splitValue = value.split(',');
  return parseFloat(splitValue[0].replaceAll('.', '') + '.' + splitValue[1]);
}

/* --------------------------- Text -------------------------- */
export const removeDiacritics = (text: string) => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const getDateFormat = (date: string | number, format: string) => {
  return dayjs(date).format(format);
};