// Libraries
import React, {ReactElement} from 'react';
import {Input, InputNumber} from 'antd';


type InputComponentType = {
  readOnly?: boolean;
  disabled?: boolean;
  placeHolder?: string;
  type?: string;
  prefix?: string | ReactElement;
  suffix?: string | ReactElement;
  className?: string;
  value?: string | number;
  title?: string;
  precision?: number;
  onChange?: (e: any) => void;
};
const InputComponent = (props: InputComponentType) => {
  const {readOnly, disabled, placeHolder, type, prefix, suffix, className, value, title, precision, onChange} = props;
  const builder = () => {
    switch (props.type){
      case 'textarea':
        return <Input.TextArea placeholder={placeHolder} autoSize={{ minRows: 2, maxRows: 3 }} value={value}
                               className={'h-9 border-[#d9d9d9] rounded hover:border-secondaryLight ' +
                                 'focus:shadow-[0_0_3px_1px_#A2BED5] ring-[transparent] focus:ring-[transparent] ' +
                                 'focus:border-secondaryDark ' +className}
                               onChange={(e) => onChange ? onChange(e) : null} />
      case 'currency':
        return <InputNumber readOnly={readOnly} disabled={disabled} placeholder={placeHolder}
                            addonBefore={prefix} addonAfter={suffix}
                            value={value} title={title} precision={precision} decimalSeparator={','}
                            className={'h-9 border-[#d9d9d9] rounded hover:border-secondaryLight ' +
                              'focus:shadow-[0_0_3px_1px_#A2BED5] ring-[transparent] focus:ring-[transparent] ' +
                              'focus:border-secondaryDark ' +
                              '[&>div>input]:text-right [&>div.ant-input-number-handler-wrap]:hidden ' + className}
                            onChange={(e) => onChange ? onChange(e) : null} />
      case 'password':
        return <Input.Password readOnly={readOnly} disabled={disabled} placeholder={placeHolder} prefix={prefix}
                               type={type} value={value} title={title}
                               className={'h-9 border-[#d9d9d9] rounded hover:border-secondaryLight ' +
                                 'focus:shadow-[0_0_3px_1px_#A2BED5] ring-[transparent] focus:ring-[transparent] ' +
                                 'focus:border-secondaryDark ' + className}
                               onChange={(e) => onChange ? onChange(e) : null} />
      default:
        return <Input readOnly={readOnly} disabled={disabled} placeholder={placeHolder} prefix={prefix}
                      type={type} value={value} title={title}
                      className={'h-9 border-[#d9d9d9] rounded hover:border-secondaryLight ' +
                        'focus:shadow-[0_0_3px_1px_#A2BED5] ring-[transparent] focus:ring-[transparent] ' +
                        'focus:border-secondaryDark ' + className}
                      onChange={(e) => onChange ? onChange(e) : null} />
    }
  }

  return (
    builder()
  );
}

export default InputComponent;