// Libraries
import {combineReducers} from 'redux';

// Reducers
import generalReducer from './generalReducer';
import accountReducer from './accountReducer';
import allianceReducer from './allianceReducer';
import geekReducer from './geekReducer';
import courseReducer from './courseReducer';
import courseSectionReducer from './courseSectionReducer';
import resourceReducer from './resourceReducer';
import studentReducer from './studentReducer';
import courseAllianceReducer from './courseAllianceReducer';
import courseStudentReducer from './courseStudentReducer';
import instructorReducer from './instructorReducer';
import courseStudentResourceReducer from "./courseStudentResourceReducer";

export default combineReducers({
  generalReducer: generalReducer,
  sessionReducer: accountReducer,
  allianceReducer: allianceReducer,
  geekReducer: geekReducer,
  courseReducer: courseReducer,
  courseAllianceReducer: courseAllianceReducer,
  courseSectionReducer: courseSectionReducer,
  courseStudentReducer: courseStudentReducer,
  courseStudentResourceReducer: courseStudentResourceReducer,
  resourceReducer: resourceReducer,
  studentReducer: studentReducer,
  instructorReducer: instructorReducer,
});
