// Libraries
import React, {ReactElement} from "react";
import {Row, Col, Form, Space, Divider, FormInstance} from "antd";
import useAccountView from "./useAccountView";

// Components
import ButtonComponent from "../subComponents/ButtonComponent";

// Types
import {AccountType} from "../../types/AccountTypes";


type PropsType = {
  title: string;
  buttonTitle: string;
  form: FormInstance<AccountType>;
  content: ReactElement;
  footerContent: ReactElement;
  onFinish: (values: AccountType) => void;
};
const AccountView = (props: PropsType) => {
  const { isAdminView } = useAccountView();
  const { title, buttonTitle, form, content, footerContent, onFinish } = props;

  return (
    <Row className={'flex min-h-screen w-full bg-login-background bg-no-repeat bg-cover'}>
      <Col md={24} xs={24} className={`flex bg-center ${isAdminView ? 'bg-tertiaryDark' : 'bg-primary'} bg-opacity-80`}>
        <Form layout={"vertical"}
              className={'flex bg-snow m-auto p-10 sm:rounded shadow shadow-black05 w-full sm:w-3/4 lg:w-1/2 h-full sm:h-fit'}
              form={form}
              onFinish={onFinish}>
          <Col className={'w-full m-auto'}>
            <Space direction={'vertical'} className={'w-full my-auto'} size={"large"}>
              <h1 className={'text-xl text-center text-secondary mb-10 font-bold uppercase'}>
                {title}
              </h1>

              <span>
                {content}
              </span>

              <ButtonComponent text={buttonTitle}
                               htmlType={'submit'}
                               className={`w-full ${isAdminView ? 'ant-btn-secondary' : 'ant-btn-primary'}`} />

              <Divider className={'m-0'} />

              <p className={'text-center w-full'}>
                {footerContent}
              </p>
            </ Space>
          </Col>
        </ Form>
      </Col>
    </Row>
  );
}

export default AccountView;