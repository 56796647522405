import request from './requestWrapper';

// Types
import {ResourceType} from "../types/ResourceTypes";


function getParams() {
  return request({
    url: 'Resource/GetParams',
    method: 'GET',
  });
}

function getAllByCourseSectionId(courseSectionId: string) {
  return request({
    url: 'Resource/GetAllByCourseSectionId',
    method: 'GET',
    params: { courseSectionId }
  });
}
function add(resource: ResourceType) {
  return request({
    url: 'Resource/Add',
    method: 'POST',
    data: resource
  });
}
function update(resource: ResourceType) {
  return request({
    url: 'Resource/Update',
    method: 'PUT',
    data: resource
  });
}
function remove(resource: ResourceType) {
  return request({
    url: 'Resource/Delete',
    method: 'DELETE',
    data: resource
  });
}

export default {
  getParams, getAllByCourseSectionId, add, update, remove
}