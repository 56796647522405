import React from "react";
import {Breadcrumb, Col, Collapse, Row} from "antd";
import {LinkOutlined,} from "@ant-design/icons";
import ReactQuill from "react-quill";
import useResourceDetail from "./useResourceDetail";

// Components
import ButtonComponent from "../../subComponents/ButtonComponent";
import Exam from "./exam/Exam";


const ResourceDetail = () => {
  const {resourceDetail, videoID, collapseItems, currentResourceName, breadcrumbItems} = useResourceDetail();

  return (
    <Row className={'w-full flex bg-secondaryDark bg-opacity-80'}>
      <Col className={'bg-snow grid grid-cols-auto grid-rows-2 md:grid-rows-1 w-full'}>
        <Col className={'col-span-12 md:col-span-4 lg:col-span-3 row-start-2 md:row-start-1 md:shadow py-2 ' +
          'md:border-r-[1px] border-r-smoke'}>
          <Collapse items={collapseItems}
                    className={'rounded-none'}
                    defaultActiveKey={resourceDetail.courseSectionId}
                    ghost={true}
                    size={'small'} />
        </Col>

        <Col  className={'col-span-12 md:col-span-8 lg:col-span-9 row-start-1 p-4 shadow md:shadow-none ' +
          'border-b-[1px] border-b-smoke md:border-none'}>
          <Col className={'mb-5'}>
            <Breadcrumb items={breadcrumbItems} />
          </Col>

          {currentResourceName === 'Video' ?
            <iframe className={'w-full h-64 md:h-80 lg:h-3/4'}
                    src={'https://www.youtube.com/embed/' + videoID + '?controls=0&rel=0&showinfo=0'}
                    title={resourceDetail.name}
                    frameBorder={"0"}
                    allow="accelerometer; autoplay; modestbranding; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen />
            : currentResourceName === 'Texto enriquecido' ?
              <ReactQuill theme={"snow"}
                          className={'[&>div.ql-toolbar]:hidden [&>div]:border-none'}
                          value={resourceDetail.value}
                          readOnly={true}  />
              : currentResourceName === 'Link externo' ?
                <Col className={'text-center'}>
                  <ButtonComponent text={'Abrir enlace externo'}
                                   icon={<LinkOutlined />}
                                   className={'ant-btn-primary'}
                                   onClick={() => window.open(resourceDetail.value, '_blank')} />
                </Col>
                : currentResourceName === 'Examen' ?
                  <Exam resourceDetail={resourceDetail} />
                  : null
          }
        </Col>
      </Col>
    </Row>
  );
}

export default ResourceDetail;