export const routes = {
  root: '/',
  profile: '/mi-perfil',
  account: {
    login: '/sesion',
    register: '/registro',
    forgotPassword: '/recuperar-contrasena',
    resetPassword: '/cambio-de-contrasena/:userId/:code',
  },
  adminAccount: {
    login: '/admin/sesion',
    register: '/admin/registro',
    forgotPassword: '/admin/recuperar-contrasena',
    resetPassword: '/admin/cambio-de-contrasena/:userId/:code',
  },
  alliances: {
    root: '/alianzas'
  },
  admins: {
    root: '/administradores'
  },
  courses: {
    root: '/cursos',
    myCourses: '/mis-cursos',
    courseDetail: '/cursos/detalle/:courseId',
    resourceDetail: '/recursos/detalle/:id',
  },
  students: {
    root: '/estudiantes'
  },
  instructors: {
    root: '/instructores'
  },
};
