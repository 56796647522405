// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import resourceServices from '../../../api/resourceServices';
import {resourceTypes} from '../types';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Types
import {ResourceType} from "../../../types/ResourceTypes";


export const getParams = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  resourceServices.getParams()
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: resourceTypes.GET_RESOURCE_PARAMS,
        resourceParams: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}

export const getAllByCourseSectionId = (courseSectionId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  dispatch({
    type: resourceTypes.GET_ALL_RESOURCES,
    resources: []
  });
  resourceServices.getAllByCourseSectionId(courseSectionId)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: resourceTypes.GET_ALL_RESOURCES,
        resources: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const add = (resource: ResourceType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  resourceServices.add(resource)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAllByCourseSectionId(resource.courseSectionId));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const update = (resource: ResourceType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  resourceServices.update(resource)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAllByCourseSectionId(resource.courseSectionId));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const remove = (resource: ResourceType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  resourceServices.remove(resource)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAllByCourseSectionId(resource.courseSectionId));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}