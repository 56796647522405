import request from './requestWrapper';

// Types
import {FiltersPaginationType} from "../types/SharedTypes";
import {CourseAllianceType} from "../types/CourseAllianceTypes";


function getParams() {
  return request({
    url: 'CourseAlliance/GetParams',
    method: 'GET',
  });
}
function getAllCourses(pagination: FiltersPaginationType) {
  return request({
    url: 'CourseAlliance/GetAllCourses',
    method: 'POST',
    data: pagination
  });
}
function getAllByAllianceId(allianceId: string, pagination: FiltersPaginationType) {
  return request({
    url: 'CourseAlliance/GetAllByAllianceId',
    method: 'POST',
    params: { allianceId },
    data: pagination
  });
}
function add(courseAlliance: CourseAllianceType) {
  return request({
    url: 'CourseAlliance/Add',
    method: 'POST',
    data: courseAlliance
  });
}
function remove(courseAlliance: CourseAllianceType) {
  return request({
    url: 'CourseAlliance/Delete',
    method: 'DELETE',
    data: courseAlliance
  });
}

export default {
  getParams, getAllCourses, getAllByAllianceId, add, remove
}