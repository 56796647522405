import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from "../../store/store";
import {getAll} from "../../store/redux/actions/geekActions";
import * as geekSelectors from '../../store/redux/selectors/geekSelectors';

// Types
import {PaginationType} from "../../types/SharedTypes";
import {GeekType} from "../../types/GeekTypes";


const useGeeks = () => {
  const dispatch = useAppDispatch();
  const [pagination, setPagination] = useState<PaginationType>({
    page: 1,
    pageSize: 12,
  });
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [geekToEdit, setGeekToEdit] = useState<GeekType | undefined>(undefined);
  const geekResponse = useSelector(geekSelectors.allGeeksSelector)!;

  useEffect(() => {
    dispatch(getAll(pagination));
  }, []);

  const handleGeekModal = (visible: boolean, geek?: GeekType) => {
    setGeekToEdit(geek);
    setFormModalVisible(visible);
  }

  return {
    formModalVisible,
    geekToEdit,
    geekResponse,
    setPagination,
    handleGeekModal,
  }
}

export default useGeeks;