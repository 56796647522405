// Libraries
import React, {useEffect, useState} from 'react';
import {Form} from 'antd';
import {BreadcrumbItemType, BreadcrumbSeparatorType} from "antd/es/breadcrumb/Breadcrumb";
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from '../../../store/store';
import {getAllByAllianceId, add, remove} from "../../../store/redux/actions/courseAllianceActions";
import * as courseAllianceSelectors from "../../../store/redux/selectors/courseAllianceSelectors";

// Types
import {AllianceType} from "../../../types/AllianceTypes";
import {FiltersPaginationType} from "../../../types/SharedTypes";
import {CourseAllianceType} from "../../../types/CourseAllianceTypes";
import {CourseType} from "../../../types/CourseTypes";


type PropsType = {
  allianceToEdit?: AllianceType;
  onToggleModal: (visible: boolean) => void;
};
const useCoursesModal = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const [pagination, setPagination] = useState<FiltersPaginationType>({
    paginationVm: {
      page: 1,
      pageSize: 10,
    },
    filterVm: {}
  });
  const [breadcrumbItems, setBreadcrumbItems] =
    useState<Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] | undefined>(
      [{
        title: <span className={'cursor-pointer'}>Información de la alianza</span>,
        onClick: () => handleSteps(1)
      }]
    );
  const [modalKey, setModalKey] = useState(1);
  const [modalStep, setModalStep] = useState(1);
  const [courseDetail, setCourseDetail] = useState<CourseType>();
  const [courseForm] = Form.useForm();
  const {allianceToEdit, onToggleModal} = props;
  const courseAllianceResponse = useSelector(courseAllianceSelectors.allCourseAllianceSelector)!;

  useEffect(() => {
    if (breadcrumbItems?.length === 1) {
      dispatch(getAllByAllianceId(allianceToEdit!.allianceId, pagination));
    }
  }, [modalKey]);

  const handleSteps = (newStep: number, stepTitle: string = '') => {
    const newBreadcrumbItems = breadcrumbItems;
    const currentStep = newBreadcrumbItems!.length;
    const isNewStep = currentStep < newStep;

    if (isNewStep) {
      courseForm.resetFields();
      newBreadcrumbItems!.push({
        title: <span className={'cursor-pointer'}>{stepTitle}</span>,
        onClick: () => handleSteps(newStep)
      });
    } else {
      newBreadcrumbItems!.length = newStep;
    }
    setBreadcrumbItems(newBreadcrumbItems);
    setModalKey(modalKey + 1);
    setModalStep(newStep);
  };

  const handleSubmit = () => {
    let values: CourseAllianceType = courseForm.getFieldsValue();
    values.allianceId = allianceToEdit!.allianceId;

    dispatch(add(values, pagination));

    handleSteps(breadcrumbItems!.length - 1);
  }

  const handleCourseDetail = (courseAlliance: CourseAllianceType) => {
    setCourseDetail(courseAlliance.course);
    handleSteps(3, 'Detalle del curso');
  }

  const handleRemoveCourse = (courseAlliance: CourseAllianceType) => {
    dispatch(remove(courseAlliance, pagination));
  }

  return {
    breadcrumbItems,
    modalKey,
    courseAllianceResponse,
    courseForm,
    courseDetail,
    modalStep,
    handleSteps,
    handleSubmit,
    handleCourseDetail,
    handleRemoveCourse,
  }
}

export default useCoursesModal;