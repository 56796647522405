// Constants
import {geekTypes} from '../types';
import {GeekReducerType} from "../../../types/GeekTypes";

const initialState = {
  geekResponse: {
    page: 1, pageSize: 12, rowCount: 0, collection: []
  }
};

const geekReducer = (state = initialState, action: GeekReducerType) => {
  switch (action.type) {
    case geekTypes.GET_ALL_GEEKS:
      return {
        ...state,
        geekResponse: action.geekResponse
      };
    default:
      return state;
  }
};

export default geekReducer;
