import React from "react";
import {Col, List, Row, Space} from "antd";
import {EditOutlined, MailOutlined, PlusOutlined} from "@ant-design/icons";
import blankProfileImage from '../../assets/images/blank-profile-image.jpg';
import useGeeks from "./useGeeks";

// Components
import ButtonComponent from "../subComponents/ButtonComponent";
import GeekFormModal from "./formModal/GeekFormModal";
import CardComponent from "../subComponents/CardComponent";

// Utils
import {LIST_PROPS} from "../../utils/componentUtils";


const Geeks = () => {
  const {formModalVisible, geekToEdit, geekResponse, setPagination, handleGeekModal} = useGeeks();

  return (
    <Row className={'w-full flex bg-secondaryDark bg-opacity-80'}>
      <Col md={24} xs={24} className={'flex bg-[transparent] py-20'}>
        <p className={'text-center m-auto text-3xl font-bold text-white'}>ADMINISTRADORES</p>
      </Col>
      <Col md={24} xs={24} className={'bg-snow py-10 px-5 sm:px-20'}>
        <Col md={24} xs={24} className={'text-right mb-10'}>
          <ButtonComponent text={'Nuevo administrador'}
                           icon={<PlusOutlined />}
                           className={'ant-btn-primary'}
                           onClick={() => handleGeekModal(true)} />
        </Col>

        <List {...LIST_PROPS(geekResponse,
          (page, pageSize) => setPagination({ page, pageSize }))}
              dataSource={geekResponse.collection}
              renderItem={(geek) => (
                <List.Item className={'h-full'}>
                  <CardComponent noImageUrl={blankProfileImage}
                                 bodyHeight={''}
                                 item={geek}
                                 className={'[&>div.ant-card-body]:p-4 sm:[&>div.ant-card-body]:p-6'}
                                 actions={[
                                   <span onClick={() => handleGeekModal(true, geek)}>
                                     <EditOutlined key={"edit"} className={'[&>svg]:fill-secondaryLight'} /> Editar administrador
                                   </span>
                                 ]}
                                 description={
                                   <Space direction={'vertical'}>
                                     <p title={geek.email} className={'text-ellipsis whitespace-nowrap overflow-hidden'}>
                                       <MailOutlined /> {geek.email}
                                     </p>
                                   </Space>
                                 } />
                </List.Item>
              )}
        />
      </Col>

      {formModalVisible &&
        <GeekFormModal geekToEdit={geekToEdit} onToggleModal={handleGeekModal} />
      }
    </Row>
  );
}

export default Geeks;