// Constants
import {courseAllianceTypes} from '../types';
import {CourseAllianceReducerType} from "../../../types/CourseAllianceTypes";

const initialState = {
  courses: [],
  courseAllianceResponse: {
    page: 1, pageSize: 12, rowCount: 0, collection: []
  }
};

const courseAllianceReducer = (state = initialState, action: CourseAllianceReducerType) => {
  switch (action.type) {
    case courseAllianceTypes.GET_COURSE_ALLIANCE_PARAMS:
      return {
        ...state,
        courses: action.courses
      };
    case courseAllianceTypes.GET_ALL_COURSE_ALLIANCE:
      return {
        ...state,
        courseAllianceResponse: action.courseAllianceResponse
      };
    default:
      return state;
  }
};

export default courseAllianceReducer;
