// Libraries
import React from "react";
import {Form} from "antd";
import {isAdminView} from "../accountHelpers";

// Store
import {useAppDispatch} from "../../../store/store";
import {forgotPassword, forgotPasswordSpecialUser} from "../../../store/redux/actions/accountActions";

// Types
import {AccountType} from "../../../types/AccountTypes";


const useForgotPassword = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const handleSubmit = (values: AccountType) => {
    if (isAdminView) {
      dispatch(forgotPasswordSpecialUser(values));
    } else {
      dispatch(forgotPassword(values));
    }
  };

  return {
    form,
    handleSubmit,
  }
}

export default useForgotPassword;
