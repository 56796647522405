import React, {useEffect, useState} from "react";
import {Form} from "antd";
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from "../../../store/store";
import {
  getProfile as getStudentProfile,
  updateProfile as updateStudentProfile
} from "../../../store/redux/actions/studentActions";
import {
  getProfile as getInstructorProfile,
  updateProfile as updateInstructorProfile
} from "../../../store/redux/actions/instructorActions";
import * as studentSelectors from "../../../store/redux/selectors/studentSelectors";
import * as instructorSelectors from "../../../store/redux/selectors/instructorSelectors";

// Utils
import {getLocalStorageItem, isEmpty, ROLE_NAMES} from "../../../utils/generalUtils";

// Types
import {StudentType} from "../../../types/StudentTypes";


const useProfile = () => {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState({ name: '' });
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [imageKey, setImageKey] = useState(0);
  const [form] = Form.useForm();
  const acceptFormat = 'image/png, image/jpg, image/jpeg';
  const currentRole = getLocalStorageItem('render_role');
  const isStudent = currentRole === ROLE_NAMES.STUDENT;
  const studentProfile = useSelector(studentSelectors.studentProfileSelector)!
  const instructorProfile = useSelector(instructorSelectors.instructorProfileSelector)! ;

  useEffect(() => {
    dispatch(isStudent ? getStudentProfile() : getInstructorProfile());
  }, []);

  useEffect(() => {
    if (!isEmpty(studentProfile) || !isEmpty(instructorProfile)) {
      isStudent && form.setFieldsValue({ alliance: studentProfile.alliance!.name });
      form.setFieldsValue({
        name: isStudent ? studentProfile.name : instructorProfile.name,
        email: isStudent ? studentProfile.email : instructorProfile.email,
        biography: isStudent ? studentProfile.biography : instructorProfile.biography,
      });
      setImageURL(isStudent ? studentProfile.imageUrl! : instructorProfile.imageUrl!);
    }
  }, [studentProfile, instructorProfile]);

  const handleSubmit = (profile: StudentType) => {
    const formData = new FormData();

    Object.keys(profile).map(key =>
      formData.append(key, profile[key as keyof typeof profile] as string)
    );
    file.name !== '' && formData.append('image', file as File, file.name);

    dispatch(isStudent ? updateStudentProfile(formData) : updateInstructorProfile(formData));
    setFile({ name: '' });
    setImageKey(imageKey + 1);
  }

  return {
    file,
    imageURL,
    imageKey,
    form,
    acceptFormat,
    isStudent,
    setFile,
    handleSubmit,
  }
}

export default useProfile;
