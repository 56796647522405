import React, {useEffect, useState} from "react";
import {Col, List} from "antd";
import type { CollapseProps } from 'antd';
import {
  AuditOutlined,
  CheckCircleOutlined,
  FileTextOutlined,
  LinkOutlined,
  YoutubeOutlined
} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

// Store
import {useAppDispatch} from "../../../store/store";
import {getParams} from "../../../store/redux/actions/resourceActions";
import * as resourceSelectors from '../../../store/redux/selectors/resourceSelectors';

// Types
import {CourseDetailType} from "../../../types/CourseTypes";

// Utils
import {routes} from "../../../utils/routes";


type PropsType = {
  courseDetail: CourseDetailType;
};
const useInstructorInfo = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const [collapseItems, setCollapseItems] = useState<CollapseProps['items']>([]);
  const { courseDetail } = props;
  const resourceParams = useSelector(resourceSelectors.resourceParamsSelector)!;
  const totalSectionsCount = courseDetail.courseSections.filter(section => section.resources.length > 0).length;
  const totalResourcesCount = courseDetail.courseSections.map(section => section.resources)
    .reduce((accumulator, resources) =>
      accumulator + resources.length, 0,
    );
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getParams());
  }, []);

  useEffect(() => {
    const items: CollapseProps['items'] = [];

    courseDetail.courseSections.map(section => {
      const resourcesCount = section.resources.length;

      section.resources.length > 0 &&
      items.push({
        key: section.courseSectionId,
        label: <b>{section.name}</b>,
        extra: `${resourcesCount} recurso${resourcesCount === 1 ? '' : 's'}`,
        children: <List dataSource={section.resources}
                        size={'small'}
                        renderItem={(resource) => {
                          const resourceType = resourceParams.resourceTypes.find(type => type.id === resource.resourceType)!.name;

                          return (
                            <List.Item className={'px-0 border-0'}>
                              <Col className={'w-full grid grid-cols-12'}>
                                <Col className={'col-span-1 text-center'}>
                                  {resourceType === 'Video'
                                    ? <YoutubeOutlined />
                                    : resourceType === 'Texto enriquecido'
                                      ? <FileTextOutlined />
                                      : resourceType === 'Link externo'
                                        ? <LinkOutlined />
                                        : <AuditOutlined />
                                  }
                                </Col>
                                <Col className={'col-span-8'}>
                                  {resource.name}
                                </Col>
                                {courseDetail.isEnrolled &&
                                  <Col className={'col-span-3 text-right'}>
                                    {resource.completed && <CheckCircleOutlined className={'text-tertiary mr-2'} />}
                                    <span className={'cursor-pointer text-complementary underline'}
                                          onClick={() =>
                                            navigate(
                                              routes.courses.resourceDetail.replace(':id', resource.resourceId),
                                              { state: { resourceDetail: resource, courseDetail }}
                                            )
                                          }>
                                      Ver recurso
                                    </span>
                                  </Col>
                                }
                              </Col>
                            </List.Item>
                          )
                        }} />,
      })
    });

    setCollapseItems(items);
  }, [courseDetail]);

  return {
    collapseItems,
    totalSectionsCount,
    totalResourcesCount,
  }
}

export default useInstructorInfo;
