import React from "react";
import {Col, Collapse, Divider, Progress, Row, Space} from "antd";
import useCourseInfo from "./useCourseInfo";

// Types
import {CourseDetailType} from "../../../types/CourseTypes";


type PropsType = {
  courseDetail: CourseDetailType
};
const InstructorInfo = (props: PropsType) => {
  const { courseDetail } = props;
  const {collapseItems, totalSectionsCount, totalResourcesCount} = useCourseInfo(props);

  return (
    <Row className={'grid grid-cols-12 gap-4'}>
      <Col className={'hidden sm:block col-span-12 sm:col-span-3'}>
        <Space direction={'vertical'} size={'large'} className={'w-full py-4 gap-12'}>
          <span className={'text-center my-10'}>
            <p className={'text-2xl'}>
              {courseDetail.enrolledStudents}
            </p>
            <p className={'text-sm capitalize'}>
              {courseDetail.enrolledStudents === 1 ? 'Estudiante inscrito' : 'Estudiantes inscritos'}
            </p>
          </span>

          {courseDetail.isEnrolled &&
            <span className={'text-center'}>
              <Progress type={"circle"}
                        percent={courseDetail.progress}
                        size={70}
                        strokeColor={'#EA345C'}
                        trailColor={'#C0CCDA'}
                        className={'w-full [&>div>span]:text-complementaryLight'} />
              <p className={'text-sm'}>
                De tu progreso
              </p>
            </span>
          }
        </Space>
      </Col>

      <Col className={'col-span-12 sm:col-span-9'}>
        <Space className={'mb-4'} split={<Divider type="vertical" />}>
          <span>{`${totalSectionsCount} secci${totalSectionsCount === 1 ? 'ón' : 'ones'}`}</span>
          <span>{`${totalResourcesCount} recurso${totalResourcesCount === 1 ? '' : 's'}`}</span>
        </Space>

        <Collapse items={collapseItems}
                  size={'small'} />
      </Col>
    </Row>
  );
}

export default InstructorInfo;