// Libraries
import React from "react";
import {MailOutlined} from '@ant-design/icons';
import useForgotPassword from "./useForgotPassword";
import {baseRoute} from "../accountHelpers";

// Components
import InputComponent from "../../subComponents/InputComponent";
import FormItemComponent from "../../subComponents/FormItemComponent";
import AccountView from "../AccountView";


const ForgotPassword = () => {
  const {form, handleSubmit} = useForgotPassword();

  return (
    <AccountView title={'Recupera tu contraseña'}
                 buttonTitle={'Recuperar contraseña'}
                 form={form}
                 content={
                   <FormItemComponent name={'email'} required={true}
                                      className={'mx-0'}
                                      child={<InputComponent type={'email'}
                                                             placeHolder={'Correo'}
                                                             prefix={<MailOutlined />} />} />

                 }
                 footerContent={
                   <>
                     {'¿Ya recuerdas tu contraseña? '}
                     <a href={baseRoute.login}
                        className={'text-secondaryLight font-bold hover:underline'}>
                       Inicia sesión
                     </a>
                   </>
                 }
                 onFinish={handleSubmit} />
  );
}

export default ForgotPassword;