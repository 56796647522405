import React, {JSX, ReactElement} from "react";
import {Col, List, Row} from "antd";

// Types
import {FiltersPaginationType, GenericResponseType} from "../../../types/SharedTypes";

// Utils
import {LIST_PROPS} from "../../../utils/componentUtils";
import {redirectWindow} from "../../../utils/generalUtils";


type PropsType = {
  showRedirectContent: boolean;
  showExtraButtons: boolean;
  sectionTitle: string;
  redirectRoute: string;
  response: GenericResponseType;
  pagination: FiltersPaginationType;
  redirectContent: ReactElement;
  extraButtonsContent: ReactElement;
  setPagination: React.Dispatch<React.SetStateAction<FiltersPaginationType>>;
  renderItem: (course: any) => JSX.Element;
};
const CoursesList = (props: PropsType) => {
  const {
    showRedirectContent, showExtraButtons, sectionTitle, redirectRoute, response,
    pagination, redirectContent, extraButtonsContent, setPagination, renderItem
  } = props;

  return (
    <Row className={'w-full flex bg-secondaryDark bg-opacity-80'}>
      <Col md={24} xs={24} className={'flex bg-[transparent] py-20'}>
        <p className={'text-center m-auto text-3xl font-bold text-white'}>
          {sectionTitle}
        </p>
      </Col>
      <Col md={24} xs={24} className={'bg-snow py-10 px-5 sm:px-20'}>
        {showExtraButtons &&
          <Col md={24} xs={24} className={'text-right mb-10'}>
            {extraButtonsContent}
          </Col>
        }

        {showRedirectContent &&
          <Col md={24} xs={24} className={'text-right mb-5 sm:mb-10 text-lg text-primary'}>
            <span className={'cursor-pointer group'} onClick={() => redirectWindow(redirectRoute)}>
              {redirectContent}
            </span>
          </Col>
        }

        <List {...LIST_PROPS(
          response,
          (page, pageSize) =>
            setPagination({ ...pagination, paginationVm: { page, pageSize }} )
        )}
              dataSource={response.collection}
              className={'[&>div>div>div>div]:my-4'}
              renderItem={renderItem}
        />
      </Col>
    </Row>
  );
}

export default CoursesList;