// Libraries
import React, {useEffect, useState} from 'react';
import {FormInstance} from 'antd';
import {useSelector} from "react-redux";

// Types
import {ResourceType} from "../../../../types/ResourceTypes";

// Store
import * as resourceSelectors from "../../../../store/redux/selectors/resourceSelectors";


type PropsType = {
  form: FormInstance<any>,
  resourceToEdit?: ResourceType;
};
const useResourceDetailModal = (props: PropsType) => {
  const {form, resourceToEdit} = props;
  const [isRichText, setIsRichText] = useState(false);
  const [isExam, setIsExam] = useState(false);
  const isEditing = resourceToEdit !== undefined;
  const {resourceTypes} = useSelector(resourceSelectors.resourceParamsSelector)!;

  useEffect(() => {
    if(isEditing) {
      form.setFieldsValue({
        name: resourceToEdit?.name,
        order: resourceToEdit?.order,
        resourceType: resourceToEdit?.resourceType,
        value: resourceToEdit?.value,
      });

      setIsRichText(resourceToEdit?.resourceType === 3);
      setIsExam(resourceToEdit?.resourceType === 4);
    }
  }, []);

  return {
    isRichText,
    isExam,
    resourceTypes,
    setIsRichText,
    setIsExam,
  }
}

export default useResourceDetailModal;