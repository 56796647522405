import React, {useEffect, useState} from "react";
import {Space} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from "../../store/store";
import {getAll} from "../../store/redux/actions/instructorActions";
import * as instructorSelectors from '../../store/redux/selectors/instructorSelectors';

// Types
import {FiltersPaginationType} from "../../types/SharedTypes";
import {InstructorType} from "../../types/InstructorTypes";

// Utils
import {useScreenSizeValidator} from "../../utils/generalUtils";


const useInstructors = () => {
  const dispatch = useAppDispatch();
  const [pagination, setPagination] = useState<FiltersPaginationType>({
    paginationVm: {
      page: 1,
      pageSize: 10,
    },
    filterVm: {}
  });
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [instructorToEdit, setInstructorToEdit] = useState<InstructorType | undefined>(undefined);
  const { page, pageSize, rowCount, collection } = useSelector(instructorSelectors.allInstructorsSelector)!;
  const isSmallWindow = useScreenSizeValidator('(max-width: 767px)');

  useEffect(() => {
    dispatch(getAll(pagination));
  }, []);

  const handleInstructorModal = (visible: boolean, instructor?: InstructorType) => {
    setInstructorToEdit(instructor);
    setFormModalVisible(visible);
  }

  const handleActionButtons = (instructor: InstructorType) => {
    return (
      <Space>
        <span className={'cursor-pointer hover:text-primary'}
              title={'Editar'}
              onClick={() => handleInstructorModal(true, instructor)}>
          <EditOutlined /> {isSmallWindow && 'Editar'}
        </span>
      </Space>
    )
  };

  return {
    formModalVisible,
    instructorToEdit,
    page,
    pageSize,
    rowCount,
    collection,
    isSmallWindow,
    handleInstructorModal,
    handleActionButtons,
  }
}

export default useInstructors;
