import request from './requestWrapper';

// Types
import {ExamType} from "../types/ResourceTypes";


function completeResource(resourceId: string) {
  return request({
    url: 'CourseStudentResource/Complete',
    method: 'POST',
    params: { resourceId }
  });
}
function completeExam(exam: ExamType) {
  return request({
    url: 'CourseStudentResource/CompleteExam',
    method: 'POST',
    data: exam
  });
}

export default {
  completeResource, completeExam
}