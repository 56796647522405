// Libraries
import React from 'react';
import {Row, Col, Form, FormInstance, Space, Empty, List, Popconfirm, Divider} from 'antd';
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import useSectionDetailModal from "./useSectionDetailModal";

// Types
import {CourseSectionType} from "../../../../types/CourseSectionTypes";
import {ResourceType} from "../../../../types/ResourceTypes";

// Components
import FormItemComponent from '../../../subComponents/FormItemComponent';
import InputComponent from '../../../subComponents/InputComponent';
import ButtonComponent from "../../../subComponents/ButtonComponent";


type PropsType = {
  canUpdate: boolean;
  form: FormInstance<any>;
  sectionToEdit?: CourseSectionType;
  handleEditResource:  (resource: ResourceType) => void;
  handleRemoveResource:  (resource: ResourceType) => void;
  handleSteps: (newStep: number, stepTitle?: string) => void;
};
const SectionDetailModal = (props: PropsType) => {
  const {form, handleEditResource, handleRemoveResource, handleSteps} = props;
  const {isEditing, resources,} = useSectionDetailModal(props);

  return (
    <Form layout={"vertical"} className={'pt-4'} form={form} initialValues={{}}>
      <Row gutter={[8, 0]} className={'pt-4'}>
        <Col md={12} xs={24}>
          <Space direction={'vertical'} size={'large'} className={'w-full'}>
            <Col md={24} xs={24}>
              <FormItemComponent name={'name'}
                                 required={props.canUpdate}
                                 label={props.canUpdate ? 'Nombre' : 'Nombre:'}
                                 child={props.canUpdate
                                   ? <InputComponent placeHolder={'Nombre'} />
                                   : <InputComponent className={'border-0 bg-[transparent] text-sm text-tertiaryDark p-0 cursor-default'}
                                                     readOnly={true}
                                                     disabled={true} />
                                 }
              />
            </Col>
            {props.canUpdate &&
              <Col md={24} xs={24}>
                <FormItemComponent name={'order'}
                                   required={true}
                                   label={'Orden'}
                                   child={<InputComponent type={'currency'} />}
                />
              </Col>
            }
          </Space>
        </Col>

        <Divider className={'md:hidden before:border-smokeDark after:border-smokeDark text-black05'}>
          Recursos
        </Divider>

        <Col md={12} xs={24}>
          {isEditing && props.canUpdate &&
            <Col md={24} xs={24} className={'text-right'}>
              <ButtonComponent text={'Nuevo recurso'}
                               icon={<PlusOutlined />}
                               className={'ant-btn-primary'}
                               onClick={() => handleSteps(3, 'Crear recurso')} />
            </Col>
          }
          <Col md={24} xs={24}>
            {(resources.length === 0 || !isEditing)
              ? <Empty description={isEditing ? 'No hay recursos para esta sección' : 'No hay recursos. Debes primero crear la sección para agregarlos.'}
                       className={'py-8'} />
              :
              <List itemLayout={"horizontal"}
                    dataSource={resources}
                    renderItem={(resource, index) => (
                      <List.Item actions={
                        props.canUpdate ?
                          [<EditOutlined key={"edit"} title={'Editar'}
                                         className={'cursor-pointer [&>svg]:fill-secondaryLight'}
                                         onClick={() => handleEditResource(resource)} />,
                            <Popconfirm title={'¿Confirmas la eliminación de esta sección?'}
                                        okText={'Confirmar'}
                                        onConfirm={() => handleRemoveResource(resource)}>
                              <DeleteOutlined key={"delete"}
                                              title={'Eliminar'}
                                              className={'cursor-pointer [&>svg]:fill-complementary'} />
                            </Popconfirm>
                          ] : []}>
                        <List.Item.Meta
                          title={
                            <Space>
                              <span className={'top-0'}>{`${index + 1}. `}</span>
                              <span>{resource.name}</span>
                            </Space>
                          } />
                      </List.Item>
                    )}
              />
            }
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

export default SectionDetailModal;