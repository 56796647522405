// Constants
import {instructorTypes} from '../types';
import {InstructorReducerType} from "../../../types/InstructorTypes";

const initialState = {
  instructorResponse: {
    page: 1, pageSize: 10, rowCount: 0, collection: []
  },
  instructorProfile: {}
};

const instructorReducer = (state = initialState, action: InstructorReducerType) => {
  switch (action.type) {
    case instructorTypes.RESET_INSTRUCTORS:
      return {
        ...state,
        instructorResponse: {
          page: 1, pageSize: 10, rowCount: 0, collection: []
        },
      };
    case instructorTypes.GET_INSTRUCTOR_PROFILE:
      return {
        ...state,
        instructorProfile: action.instructorProfile
      };
    case instructorTypes.GET_ALL_INSTRUCTORS:
      return {
        ...state,
        instructorResponse: action.instructorResponse
      };
    default:
      return state;
  }
};

export default instructorReducer;
