// Libraries
import React, {useEffect, useState} from 'react';
import {FormInstance, FormListFieldData} from 'antd';

// Utils
import {isEmpty} from "../../../../utils/generalUtils";

// Types
import {ResourceType} from "../../../../types/ResourceTypes";


type PropsType = {
  form: FormInstance<any>;
  resourceToEdit?: ResourceType;
};
type AnswerOptionType = {
  id: number;
  name: string;
};
type QuestionListType = {
  key: number;
  name: number;
  answerOptions: AnswerOptionType[];
}
const useExamSection = (props: PropsType) => {
  const [questionList, setQuestionList] = useState<QuestionListType[]>([]);
  const [componentKey, setComponentKey] = useState<number>(0);
  const {form, resourceToEdit} = props;
  const fieldNameBase = ['exam', 'questions'];

  useEffect(() => {
    if(resourceToEdit) {
      const newQuestionList: QuestionListType[] = [];
      const {durationInMinutes, questions} = resourceToEdit?.exam;

      form.setFieldValue(['exam', 'durationInMinutes'], durationInMinutes);
      form.setFieldValue(fieldNameBase, questions);

      questions.map((question, index) => {
        const correctOptionIndex = question.answers.findIndex(answer => answer.isCorrect);

        form.setFieldValue([...fieldNameBase, index.toString(), 'answers', 'correctOption'], correctOptionIndex + 1);
        newQuestionList.push({
          key: newQuestionList.length,
          name: newQuestionList.length,
          answerOptions: question.answers.map((answer, index) => ({ id: index, name: `Opción ${index + 1}` }))
        });
      });
      setQuestionList(newQuestionList);
    }
  }, []);

  const handleAddQuestion = () => {
    const newQuestionList: QuestionListType[] = [...questionList];
    let newQuestionObject = form.getFieldValue(fieldNameBase) ?? [];

    newQuestionObject.push({
      content: null,
      answers: new Array(4).fill({ content: null, isCorrect: null })
    });

    newQuestionList.push({
      key: questionList.length,
      name: questionList.length,
      answerOptions: [0, 1, 2, 3].map(option => ({ id: option, name: `Opción ${option + 1}` }))
    });
    form.setFieldValue(fieldNameBase, newQuestionObject);
    setQuestionList(newQuestionList);
  }

  const handleRemoveQuestion = (keyToRemove: number) => {
    const newQuestionList: QuestionListType[] = [];
    let newQuestionObject = form.getFieldValue(fieldNameBase);
    newQuestionObject.splice(keyToRemove, 1);

    newQuestionObject.map((question: any, index: number) => {
      newQuestionList.push({ key: index, name: index, answerOptions: [] });
    })

    form.setFieldValue(fieldNameBase, isEmpty(newQuestionObject) ? null : newQuestionObject);
    setQuestionList(newQuestionList);
  }

  const handleChangeCorrectAnswer = (correctOptionId: string, questionId: number) => {
    const fieldNameBase = ['exam', 'questions', questionId];
    const { answerOptions } = questionList.find(question => question.key === questionId)!;

    answerOptions.forEach(option => {
      form.setFieldValue(
        [...fieldNameBase, 'answers', option.id, 'isCorrect'],
        option.id === parseInt(correctOptionId)
      );
      form.setFieldValue([...fieldNameBase, 'answers', 'correctOption'], correctOptionId);
    })
  }

  const handleAnswerOption = (isAdding: boolean, questionId: number, optionId?: number) => {
    const fieldNameBase = ['exam', 'questions', questionId];
    const newQuestionList = questionList;
    const { answerOptions } = questionList.find(question => question.key === questionId)!;

    if (isAdding) {
      const answerOptionsCount = answerOptions.length;

      answerOptions.push({
        id: answerOptionsCount, name: `Opción ${answerOptionsCount + 1}`
      });
      form.setFieldValue(
        [...fieldNameBase, 'answers', answerOptionsCount],
        { content: null, isCorrect: false }
      );
    } else {
      const { getFieldValue } = form;
      const newAnswers: { content: string; isCorrect?: boolean; }[] = [];
      answerOptions.splice(optionId!, 1);

      answerOptions.forEach((option, index) => {
        const currentContent = getFieldValue([...fieldNameBase, 'answers', index >= optionId! ? index + 1 : index]);
        option.id = index;
        option.name = `Opción ${option.id + 1}`;

        newAnswers.push({
          ...currentContent,
          isCorrect: null
        });
      });

      form.setFieldValue([...fieldNameBase, 'answers'], newAnswers);
    }

    setQuestionList(newQuestionList);
    setComponentKey(componentKey + 1);
  }

  return {
    componentKey,
    questionList,
    handleAddQuestion,
    handleRemoveQuestion,
    handleAnswerOption,
    handleChangeCorrectAnswer,
  }
}

export default useExamSection;