import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from "../../store/store";
import {getAll} from "../../store/redux/actions/allianceActions";
import * as allianceSelectors from '../../store/redux/selectors/allianceSelectors';

// Types
import {PaginationType} from "../../types/SharedTypes";
import {AllianceType} from "../../types/AllianceTypes";


type PropsType = {
  canCreate: boolean;
  canUpdate: boolean;
  canRemove: boolean;
};
const useAlliances = (props: PropsType) => {
  const [pagination, setPagination] = useState<PaginationType>({
    page: 1,
    pageSize: 12,
  });
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [coursesModalVisible, setCoursesModalVisible] = useState(false);
  const [allianceToEdit, setAllianceToEdit] = useState<AllianceType | undefined>(undefined);
  const dispatch = useAppDispatch();
  const allianceResponse = useSelector(allianceSelectors.allAlliancesSelector)!;

  useEffect(() => {
    dispatch(getAll(pagination));
  }, []);

  const handleAllianceModal = (visible: boolean, alliance?: AllianceType) => {
    setAllianceToEdit(alliance);
    setFormModalVisible(visible);
  }

  const handleCoursesModal = (visible: boolean, alliance?: AllianceType) => {
    setAllianceToEdit(alliance);
    setCoursesModalVisible(visible);
  }

  return {
    formModalVisible,
    coursesModalVisible,
    allianceToEdit,
    allianceResponse,
    setPagination,
    handleAllianceModal,
    handleCoursesModal,
  }
}

export default useAlliances;