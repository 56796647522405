import request from './requestWrapper';

// Types
import {FiltersPaginationType} from "../types/SharedTypes";
import {CourseStudentType} from "../types/CourseStudentTypes";


function getAll(pagination: FiltersPaginationType) {
  return request({
    url: 'CourseStudent/GetAll',
    method: 'POST',
    data: pagination
  });
}
function add(courseStudent: CourseStudentType) {
  return request({
    url: 'CourseStudent/Add',
    method: 'POST',
    data: courseStudent
  });
}

export default {
  getAll, add
}