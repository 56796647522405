// Libraries
import React, {useEffect, useState} from 'react';
import {Form} from 'antd';
import dayjs from "dayjs";

// Store
import {useAppDispatch} from '../../../store/store';
import { add, update } from '../../../store/redux/actions/allianceActions';

// Types
import {AllianceType} from "../../../types/AllianceTypes";

// Utils
import {DATETIME_FORMAT, getDateFormat} from "../../../utils/generalUtils";


type PropsType = {
  allianceToEdit?: AllianceType;
  onToggleModal: (visible: boolean) => void;
};
const useAllianceFormModal = (props: PropsType) => {
  const {allianceToEdit, onToggleModal} = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const isEditing = allianceToEdit !== undefined;
  const [file, setFile] = useState({ name: '' });
  const acceptFormat = 'image/png, image/jpg, image/jpeg';

  useEffect(() => {
    if(isEditing) {
      form.setFieldsValue({
        isActive: allianceToEdit?.isActive,
        name: allianceToEdit?.name,
        email: allianceToEdit?.email,
        startDate: [dayjs(allianceToEdit?.startDate), dayjs(allianceToEdit?.endDate)],
      });
    }
  }, []);

  const handleSubmit = (alliance: AllianceType) => {
    const formData = new FormData();
    const dates = [
      new Date(alliance.startDate[0]).setHours(0, 0, 0),
      new Date(alliance.startDate[1]).setHours(0, 0, 0)
    ];
    alliance.startDate = getDateFormat(dates[0], DATETIME_FORMAT);
    alliance.endDate = getDateFormat(dates[1], DATETIME_FORMAT);
    alliance.image = undefined;

    if(isEditing) {
      alliance.allianceId = allianceToEdit!.allianceId!;
    } else {
      alliance.isActive = true;
    }
    Object.keys(alliance).map(key => {
      if(key !== 'image'){
        formData.append(key, alliance[key as keyof typeof alliance] as string);
      } else {
        file.name !== '' && formData.append(key, file as File, file.name);
      }
    });

    isEditing ? dispatch(update(formData)) : dispatch(add(formData));
    onToggleModal(false);
  }

  return {
    form,
    isEditing,
    file,
    acceptFormat,
    setFile,
    handleSubmit,
  }
}

export default useAllianceFormModal;