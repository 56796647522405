// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import allianceServices from '../../../api/allianceServices';
import {allianceTypes} from '../types';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Types
import {PaginationType} from "../../../types/SharedTypes";


export const getAll = (pagination: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  allianceServices.getAll(pagination)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: allianceTypes.GET_ALL_ALLIANCES,
        allianceResponse: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const add = (alliance: FormData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  allianceServices.add(alliance)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ page: 1, pageSize: 12 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const update = (alliance: FormData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  allianceServices.update(alliance)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ page: 1, pageSize: 12 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}