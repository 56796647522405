// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import instructorServices from '../../../api/instructorServices';
import {instructorTypes} from '../types';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Types
import {FiltersPaginationType} from "../../../types/SharedTypes";
import {InstructorType} from "../../../types/InstructorTypes";


export const getProfile = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  instructorServices.getProfile()
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: instructorTypes.GET_INSTRUCTOR_PROFILE,
        instructorProfile: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const getAll = (pagination: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  instructorServices.getAll(pagination)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: instructorTypes.GET_ALL_INSTRUCTORS,
        instructorResponse: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const add = (instructor: InstructorType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  instructorServices.add(instructor)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ paginationVm: { page: 1, pageSize: 10 } }));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const update = (instructor: InstructorType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  instructorServices.update(instructor)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ paginationVm: { page: 1, pageSize: 10 } }));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const updateProfile = (instructor: FormData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  instructorServices.updateProfile(instructor)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getProfile());
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}