// Libraries
import React, {ReactElement} from 'react';
import {Button} from 'antd';

// Functions
import {isEmpty} from '../../utils/generalUtils';


type ButtonComponentType = {
  htmlType?: 'submit' | 'reset' | 'button' | undefined;
  className?: string;
  text: string;
  loading?: boolean;
  icon?: ReactElement | null;
  onClick?: () => void;
};
const ButtonComponent = (props: ButtonComponentType) => {
  const { htmlType, className, text, loading, icon, onClick } = props;
  const textIsEmpty = isEmpty(text);

  return (
    <Button htmlType={htmlType} loading={loading} icon={icon}
            className={`${textIsEmpty ? 'h-7 ' : 'h-fit '}` +
              'rounded-md ext-sm font-medium shadow-md ' + className
            }
            onClick={onClick}>
      {text}
    </Button>
  );
}

export default ButtonComponent;