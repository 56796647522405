import request from './requestWrapper';

// Types
import {FiltersPaginationType} from "../types/SharedTypes";
import {StudentType} from "../types/StudentTypes";


function getParams() {
  return request({
    url: 'Student/GetParams',
    method: 'GET',
  });
}
function getProfile() {
  return request({
    url: 'Student/GetProfile',
    method: 'GET',
  });
}
function getAllByAllianceId(pagination: FiltersPaginationType, allianceId?: string) {
  return request({
    url: 'Student/GetAllByAllianceId',
    method: 'POST',
    params: allianceId ? { allianceId } : null,
    data: pagination
  });
}
function add(student: StudentType) {
  return request({
    url: 'Student/Add',
    method: 'POST',
    data: student
  });
}
function update(student: StudentType) {
  return request({
    url: 'Student/Update',
    method: 'PUT',
    data: student
  });
}
function updateProfile(student: FormData) {
  return request({
    url: 'Student/UpdateProfile',
    method: 'PUT',
    data: student
  });
}
function remove(student: StudentType) {
  return request({
    url: 'Student/Delete',
    method: 'DELETE',
    data: student
  });
}

export default {
  getParams, getProfile, getAllByAllianceId, add, update, updateProfile, remove
}