import React from "react";
import {Alert, Card, Col, List, Row, Space} from "antd";
import {CheckOutlined, CloseOutlined, EditOutlined, MailOutlined, PlusOutlined} from "@ant-design/icons";
import useStudents from "./useStudents";

// Components
import ButtonComponent from "../subComponents/ButtonComponent";
import StudentsFormModal from "./formModal/StudentsFormModal";
import SelectComponent from "../subComponents/SelectComponent";
import TableComponent from "../subComponents/TableComponent";

// Utils
import {isEmpty} from "../../utils/generalUtils";
import {tableColumns} from "../../utils/tableColumns";

// Types
import {StudentType} from "../../types/StudentTypes";


const Students = () => {
  const {
    formModalVisible, studentToEdit, selectedAllianceId, page, pageSize, rowCount, collection, alliances,
    isAlliance, isSmallWindow, setSelectedAllianceId, handleStudentModal, handleActionButtons
  } = useStudents();

  return (
    <Row className={'w-full flex bg-secondaryDark bg-opacity-80'}>
      <Col md={24} xs={24} className={'flex bg-[transparent] py-20'}>
        <p className={'text-center m-auto text-3xl font-bold text-white'}>ESTUDIANTES</p>
      </Col>
      <Col md={24} xs={24} className={'bg-snow py-0 md:py-10 px-5 sm:px-10 md:px-20'}>
        <Col  md={24} xs={24} className={'sm:block md:flex'}>
          <Col md={12} xs={12} className={'my-5 md:mb-10'}>
            {!isAlliance &&
              <SelectComponent idIdentifier={'customId'}
                               valueIdentifier={'name'}
                               list={alliances}
                               onChange={(allianceId) => setSelectedAllianceId(allianceId)}
                               placeHolder={'Selecciona una alianza'} />
            }
          </Col>
          {(selectedAllianceId || isAlliance) &&
            <Col md={12} xs={24} className={'text-right my-5 md:mb-10'}>
              <ButtonComponent text={'Nuevo estudiante'}
                               icon={<PlusOutlined />}
                               className={'ant-btn-primary'}
                               onClick={() => handleStudentModal(true)} />
            </Col>
          }
        </Col>

        {(!isAlliance && isEmpty(collection))
          ? <Alert type={'info'}
                   message={'No hay estudiantes para el filtro seleccionado. Por favor selecciona otra alianza.'} />
          : isSmallWindow
            ? <List grid={{ gutter: 16, sm: 2, xs: 1 }}
                    dataSource={collection}
                    renderItem={(student: StudentType, index) => (
                      <List.Item>
                        <Card hoverable
                              bordered={true}
                              className={'border-smokeExtraDark [&>div.ant-card-cover]:m-0'}
                              actions={[
                                handleActionButtons(student)
                              ]}
                        >
                          <Card.Meta title={<span title={student.name}>{student.name}</span>}
                                     description={
                                       <Space direction={'vertical'}>
                                         <span className={'font-semibold'} title={student.email}>
                                           <MailOutlined /> {student.email}
                                         </span>
                                         <span className={`font-semibold ${student.isActive ? 'text-tertiary' : 'text-complementary'}`}>
                                           {student.isActive
                                             ? <span><CheckOutlined /> {'Activo'}</span>
                                             : <span><CloseOutlined /> {'Inactivo'}</span>
                                           }
                                         </span>
                                       </Space>} />
                        </Card>
                      </List.Item>
                    )}
            />
            : <TableComponent pageSize={pageSize}
                              className={'shadow-md'}
                              currentPage={page}
                              total={rowCount}
                              scrollX={800}
                              filterText={''}
                              rowKey={'studentId'}
                              dataSource={collection}
                              columns={tableColumns('students', handleActionButtons)}
                              onChangePage={(pagination) => console.log(pagination)} />
        }
      </Col>

      {formModalVisible &&
        <StudentsFormModal studentToEdit={studentToEdit}
                           selectedAllianceId={selectedAllianceId}
                           onToggleModal={handleStudentModal} />
      }
    </Row>
  );
}

export default Students;