// Libraries
import React from 'react';
import {Table} from 'antd';

// Types
import {PaginationType} from '../../types/SharedTypes';


type TableComponentType = {
  pageSize: number;
  currentPage: number;
  total: number;
  scrollX: number;
  filterText: string;
  rowKey: string;
  className?: string;
  dataSource: any[];
  columns: any[];
  onChangePage: (pagination: PaginationType) => void;
};
const TableComponent = (props: TableComponentType) => {
  const {pageSize, currentPage, total, scrollX, filterText, rowKey, className, dataSource, columns, onChangePage} = props;

  return (
    <Table dataSource={dataSource} rowKey={rowKey} size={'small'} bordered={true}
           className={className}
           columns={columns}
           scroll={{ x: scrollX }}
           pagination={{
             size: 'small',
             pageSize: pageSize,
             current: currentPage,
             showSizeChanger: true,
             hideOnSinglePage: true,
             total: total,
             onChange: (page, pageSize) => onChangePage({ page: page, pageSize: pageSize }),
           }} />
  );
}

export default TableComponent;