// Libraries
import React, {useEffect} from 'react';
import {FormInstance} from 'antd';
import {useSelector} from "react-redux";

// Types
import {CourseSectionType} from "../../../../types/CourseSectionTypes";

// Store
import {useAppDispatch} from "../../../../store/store";
import {getAllByCourseSectionId, getParams} from "../../../../store/redux/actions/resourceActions";
import * as resourceSelectors from "../../../../store/redux/selectors/resourceSelectors";


type PropsType = {
  form: FormInstance<any>;
  sectionToEdit?: CourseSectionType;
};
const useSectionDetailModal = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const {form, sectionToEdit} = props;
  const isEditing = sectionToEdit !== undefined;
  const resources = useSelector(resourceSelectors.allResourcesSelector)!;

  useEffect(() => {
    dispatch(getParams());

    if(isEditing) {
      dispatch(getAllByCourseSectionId(sectionToEdit?.courseSectionId!));
      form.setFieldsValue({
        name: sectionToEdit?.name,
        order: sectionToEdit?.order
      });
    }
  }, []);

  return {
    isEditing,
    resources,
  }
}

export default useSectionDetailModal;