// Libraries
import React, {useEffect, useState} from 'react';
import {Form} from 'antd';
import dayjs from "dayjs";

// Store
import {useAppDispatch} from '../../../store/store';
import { add, update } from '../../../store/redux/actions/courseActions';

// Types
import {CourseType} from "../../../types/CourseTypes";

// Utils
import {DATETIME_FORMAT, getDateFormat} from "../../../utils/generalUtils";


type PropsType = {
  courseToEdit?: CourseType;
  onToggleModal: (visible: boolean) => void;
};
const useCourseFormModal = (props: PropsType) => {
  const {courseToEdit, onToggleModal} = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const isEditing = courseToEdit !== undefined;
  const [file, setFile] = useState({ name: '' });
  const acceptFormat = 'image/png, image/jpg, image/jpeg';

  useEffect(() => {
    if(isEditing) {
      form.setFieldsValue({
        name: courseToEdit?.name,
        description: courseToEdit?.description,
        releaseDate: dayjs(courseToEdit?.releaseDate),
      });
    }
  }, []);

  const handleSubmit = (course: CourseType) => {
    const formData = new FormData();
    const date = new Date(course.releaseDate).setHours(0, 0, 0);
    course.releaseDate = getDateFormat(date, DATETIME_FORMAT);
    course.image = undefined;

    if(isEditing) {
      course.courseId = courseToEdit!.courseId;
    }
    Object.keys(course).map(key => {
      if(key !== 'image'){
        formData.append(key, course[key as keyof typeof course] as string);
      } else {
        file.name !== '' && formData.append(key, file as File, file.name);
      }
    });

    isEditing ? dispatch(update(formData)) : dispatch(add(formData));
    onToggleModal(false);
  }

  return {
    form,
    isEditing,
    file,
    acceptFormat,
    setFile,
    handleSubmit,
  }
}

export default useCourseFormModal;