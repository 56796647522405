// Libraries
import React, {useEffect} from 'react';
import {Form} from 'antd';

// Store
import {useAppDispatch} from '../../../store/store';
import { add, update } from '../../../store/redux/actions/geekActions';

// Types
import {GeekType} from "../../../types/GeekTypes";


type GeekFormModalType = {
  geekToEdit?: GeekType;
  onToggleModal: (visible: boolean) => void;
};
const useGeekFormModal = (props: GeekFormModalType) => {
  const dispatch = useAppDispatch();
  const {geekToEdit, onToggleModal} = props;
  const [form] = Form.useForm();
  const isEditing = geekToEdit !== undefined;

  useEffect(() => {
    if(isEditing) {
      form.setFieldsValue({
        isActive: geekToEdit?.isActive,
        name: geekToEdit?.name,
        email: geekToEdit?.email,
      });
    }
  }, []);

  const handleSubmit = (geek: GeekType) => {
    if(isEditing) {
      geek.geekId = geekToEdit!.geekId;
      geek.imageUrl = geekToEdit!.imageUrl;
    } else {
      geek.isActive = true;
    }

    isEditing ? dispatch(update(geek)) : dispatch(add(geek));
    onToggleModal(false);
  }

  return {
    form,
    isEditing,
    handleSubmit,
  }
}

export default useGeekFormModal;