import request from './requestWrapper';

// Types
import {PaginationType} from "../types/SharedTypes";
import {GeekType} from "../types/GeekTypes";


function getAll(pagination: PaginationType) {
  return request({
    url: 'Geek/GetAll',
    method: 'POST',
    data: pagination
  });
}
function add(geek: GeekType) {
  return request({
    url: 'Geek/Add',
    method: 'POST',
    data: geek
  });
}
function update(geek: GeekType) {
  return request({
    url: 'Geek/Update',
    method: 'PUT',
    data: geek
  });
}

export default {
  getAll, add, update
}