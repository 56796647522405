// Libraries
import React from 'react';
import dayjs from "dayjs";
import {Row, Col, Modal, Space, Empty, List, Popconfirm, Breadcrumb, Divider} from 'antd';
import {CloseOutlined, EyeOutlined, PlusOutlined} from "@ant-design/icons";
import useCoursesModal from "./useCoursesModal";

// Types
import {AllianceType} from "../../../types/AllianceTypes";

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';
import CourseFormModal from "./courseFormModal/CourseFormModal";
import CourseDetailModal from "./courseDetailModal/CourseDetailModal";

// Utils
import {DATE_FORMAT} from "../../../utils/generalUtils";


type PropsType = {
  allianceToEdit?: AllianceType;
  onToggleModal: (visible: boolean) => void;
};
const CoursesModal = (props: PropsType) => {
  const {allianceToEdit, onToggleModal} = props;
  const {
    breadcrumbItems, modalKey, courseAllianceResponse, courseForm, courseDetail, modalStep,
    handleSteps, handleSubmit, handleCourseDetail, handleRemoveCourse
  } = useCoursesModal(props);

  return (
    <Modal key={modalKey} title={allianceToEdit?.name}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           width={modalStep === 2 ? 400 : 1000}
           footer={[
             <ButtonComponent key={'cancel'}
                              text={'Cerrar'}
                              onClick={() => onToggleModal(false)} />,
             modalStep > 1 &&
               <ButtonComponent key={'back'}
                                text={'Regresar'}
                                onClick={() => handleSteps(breadcrumbItems!.length - 1)} />,
             modalStep === 2 &&
               <ButtonComponent key={'submit'}
                                text={'Agregar curso'}
                                className={'ant-btn-primary'}
                                onClick={handleSubmit} />
           ]}>
      <>
        <Breadcrumb className={'mb-4 italic'} items={breadcrumbItems} />

        {modalStep === 1
          ?
          <Row gutter={[8, 0]} className={'pt-4'}>
            <Col md={12} xs={24}>
              <Space direction={'vertical'} size={'large'}
                     className={'w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-3 [&>div]:col-span-1'}>
                <Col md={24} xs={24}>
                  <p className={'font-bold'}>Allianza:</p>
                  <p>{allianceToEdit?.name}</p>
                </Col>
                <Col md={24} xs={24}>
                  <p className={'font-bold'}>Correo:</p>
                  <p>{allianceToEdit?.email}</p>
                </Col>
                <Col md={24} xs={24}>
                  <p className={'font-bold'}>Fecha de inicio:</p>
                  <p>{dayjs(allianceToEdit?.startDate).format(DATE_FORMAT)}</p>
                </Col>
                <Col md={24} xs={24}>
                  <p className={'font-bold'}>Fecha de fin:</p>
                  <p>{dayjs(allianceToEdit?.endDate).format(DATE_FORMAT)}</p>
                </Col>
              </Space>
            </Col>

            <Divider className={'md:hidden before:border-smokeDark after:border-smokeDark text-black05'}>
              Cursos
            </Divider>

            <Col md={12} xs={24}>
              <Col md={24} xs={24} className={'text-right'}>
                <ButtonComponent text={'Agregar curso'}
                                 icon={<PlusOutlined />}
                                 className={'ant-btn-primary mb-4'}
                                 onClick={() => handleSteps(2, 'Agregar curso')} />
              </Col>
              <Col md={24} xs={24}>
                {courseAllianceResponse.collection.length === 0
                  ? <Empty description={'No hay cursos para esta alianza'}
                           className={'py-8'} />
                  :
                  <List itemLayout={"horizontal"}
                        dataSource={courseAllianceResponse.collection}
                        renderItem={(courseAlliance, index) => (
                          <List.Item actions={
                            [<EyeOutlined key={"view"}
                                          title={'Ver detalle'}
                                          className={'cursor-pointer [&>svg]:fill-secondaryLight'}
                                          onClick={() => handleCourseDetail(courseAlliance)} />,
                              <Popconfirm title={'¿Confirmas la eliminación de este curso?'}
                                          okText={'Confirmar'}
                                          onConfirm={() => handleRemoveCourse(courseAlliance)}>
                                <CloseOutlined key={"delete"}
                                               title={'Eliminar curso de esta alianza'}
                                               className={'cursor-pointer [&>svg]:fill-complementary'} />
                              </Popconfirm>
                            ]}>
                            <List.Item.Meta
                              title={
                                <Space>
                                  <span className={'top-0'}>{`${index + 1}. `}</span>
                                  <span>{courseAlliance.course.name}</span>
                                </Space>
                              } />
                          </List.Item>
                        )}
                  />
                }
              </Col>
            </Col>
          </Row>
          : modalStep === 2
            ? <CourseFormModal form={courseForm} />
            : <CourseDetailModal courseDetail={courseDetail} />
        }
      </>
    </Modal>
  );
}

export default CoursesModal;