// Libraries
import React from 'react';
import dayjs from "dayjs";
import {Row, Col, Modal, Space, Empty, List, Popconfirm, Breadcrumb, Divider, FloatButton} from 'antd';
import {DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined} from "@ant-design/icons";
import useSectionsModal from "./useSectionsModal";

// Types
import {CourseType} from "../../../types/CourseTypes";

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';
import SectionDetailModal from "./sectionDetail/SectionDetailModal";
import ResourceDetailModal from "./resources/ResourceDetailModal";

// Utils
import {DATE_FORMAT} from "../../../utils/generalUtils";


type PropsType = {
  canUpdate: boolean;
  courseDetail?: CourseType;
  onToggleModal: (visible: boolean) => void;
};
const SectionsModal = (props: PropsType) => {
  const {courseDetail, onToggleModal} = props;
  const {
    breadcrumbItems, modalKey, sectionForm, resourceForm, courseSectionToEdit, resourceToEdit, courseSectionResponse,
    handleEditSection, handleRemoveSection, handleSectionDetail, handleEditResource, handleRemoveResource,
    handleSteps, handleSubmit
  } = useSectionsModal(props);

  return (
    <Modal key={modalKey}
           title={courseDetail?.name}
           open={true}
           maskClosable
           closable={false}
           keyboard={false}
           destroyOnClose={true}
           width={breadcrumbItems!.length >= 2 ? 800 : 1000}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              onClick={() => onToggleModal(false)} />,
             breadcrumbItems!.length > 1 &&
               <ButtonComponent key={'back'} text={'Regresar'}
                                onClick={() => handleSteps(breadcrumbItems!.length - 1)} />,
             breadcrumbItems!.length > 1 && props.canUpdate &&
               <ButtonComponent key={'submit'}
                                text={(breadcrumbItems!.length === 2 && courseSectionToEdit) || (breadcrumbItems!.length === 3 && resourceToEdit)
                                  ? 'Editar' : 'Crear'
                                }
                                className={'ant-btn-primary'}
                                onClick={handleSubmit} />
           ]}>
      <>
        <Breadcrumb className={'mb-4 italic'} items={breadcrumbItems} />

        {breadcrumbItems!.length === 1
          ?
          <Row gutter={[8, 0]} className={'pt-4'}>
            <Col md={12} xs={24}>
              <Space direction={'vertical'} size={'large'}>
                <Col md={24} xs={24}>
                  <p className={'font-bold'}>Nombre del curso:</p>
                  <p>{courseDetail?.name}</p>
                </Col>
                <Col md={24} xs={24}>
                  <p className={'font-bold'}>Descripción:</p>
                  <p>{courseDetail?.description}</p>
                </Col>
                <Col md={24} xs={24}>
                  <p className={'font-bold'}>Fecha de lanzamiento:</p>
                  <p>{dayjs(courseDetail?.releaseDate).format(DATE_FORMAT)}</p>
                </Col>
              </Space>
            </Col>

            <Divider className={'md:hidden before:border-smokeDark after:border-smokeDark text-black05'}>
              Secciones
            </Divider>

            <Col md={12} xs={24}>
              {props.canUpdate &&
                <Col md={24} xs={24} className={'text-right'}>
                  <ButtonComponent text={'Nueva sección'}
                                   icon={<PlusOutlined />}
                                   className={'ant-btn-primary'}
                                   onClick={() => handleSteps(2, 'Crear sección')} />
                </Col>
              }
              <Col md={24} xs={24}>
                {courseSectionResponse.length === 0
                  ? <Empty description={'No hay secciones para este curso'} className={'py-8'} />
                  :
                  <List itemLayout={"horizontal"}
                        dataSource={courseSectionResponse}
                        renderItem={(section, index) => (
                          <List.Item actions={
                            props.canUpdate ?
                              [<EditOutlined key={"edit"} title={'Editar'}
                                             className={'cursor-pointer [&>svg]:fill-secondaryLight'}
                                             onClick={() => handleEditSection(section)} />,
                                <Popconfirm title={'¿Confirmas la eliminación de esta sección?'}
                                            okText={'Confirmar'}
                                            onConfirm={() => handleRemoveSection(section)}>
                                  <DeleteOutlined key={"delete"}
                                                  title={'Eliminar'}
                                                  className={'cursor-pointer [&>svg]:fill-complementary'} />
                                </Popconfirm>
                              ] : [
                                <EyeOutlined key={"view"}
                                             title={'Ver detalle'}
                                             className={'cursor-pointer [&>svg]:fill-secondaryLight'}
                                             onClick={() => handleSectionDetail(section)} />
                              ]}>
                            <List.Item.Meta
                              title={
                                <Space>
                                  <span className={'top-0'}>{`${index + 1}. `}</span>
                                  <span>{section.name}</span>
                                </Space>
                              }
                              description={`${section.resources.length} recurso${section.resources.length === 1 ? ' disponible' : 's disponibles'}`} />
                          </List.Item>
                        )}
                  />
                }
              </Col>
            </Col>
          </Row>
          : breadcrumbItems!.length === 2
            ? <SectionDetailModal canUpdate={props.canUpdate}
                                  form={sectionForm}
                                  sectionToEdit={courseSectionToEdit}
                                  handleEditResource={handleEditResource}
                                  handleRemoveResource={handleRemoveResource}
                                  handleSteps={handleSteps} />
            : <ResourceDetailModal form={resourceForm}
                                   resourceToEdit={resourceToEdit} />
        }

        <FloatButton.BackTop target={() => document.getElementsByClassName('ant-modal-wrap')[0]! as HTMLElement} />
      </>
    </Modal>
  );
}

export default SectionsModal;