// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import geekServices from '../../../api/geekServices';
import {geekTypes} from '../types';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Types
import {PaginationType} from "../../../types/SharedTypes";
import {GeekType} from "../../../types/GeekTypes";


export const getAll = (pagination: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  geekServices.getAll(pagination)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: geekTypes.GET_ALL_GEEKS,
        geekResponse: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const add = (geek: GeekType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  geekServices.add(geek)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ page: 1, pageSize: 12 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const update = (geek: GeekType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  geekServices.update(geek)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ page: 1, pageSize: 12 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}