import React from "react";
import {Col, Form, Image, Row} from "antd";
import baseURL from "../../../api/baseURL";
import {SaveOutlined} from "@ant-design/icons";
import useProfile from "./useProfile";

// Components
import UploadComponent from "../UploadComponent";
import FormItemComponent from "../FormItemComponent";
import InputComponent from "../InputComponent";
import ButtonComponent from "../ButtonComponent";

// Constants
import noImagePath from '../../../assets/images/blank-profile-image.jpg';


const Profile = () => {
  const {file, imageURL, imageKey, form, acceptFormat, isStudent, setFile, handleSubmit} = useProfile();

  return (
    <Row className={'w-full flex bg-secondaryDark bg-opacity-80'}>
      <Col md={24} xs={24} className={'bg-snow py-10 px-5 sm:px-10 md:px-20'}>
        <Col md={24} xs={24} className={'bg-white h-full shadow border border-smokeExtraDark rounded p-4'}>
          <Form layout={"vertical"} className={'pt-4 w-full'} form={form} onFinish={handleSubmit}>
            <Row gutter={[8, 0]}>
              <Col md={12} xs={24} className={''}>
                <Col md={24} xs={24} key={imageKey}>
                  {<Image src={imageURL ? baseURL! + imageURL : noImagePath}
                          preview={imageURL !== null}
                          className={'w-1/3 m-auto'} />}
                  <UploadComponent acceptFormat={acceptFormat}
                                   uploadText={'Cambiar imagen'}
                                   file={file as File}
                                   setFile={setFile} />
                </Col>
              </Col>
              <Col md={12} xs={24}>
                {isStudent &&
                  <Col md={24} xs={24}>
                    <FormItemComponent name={'alliance'}
                                       required={true}
                                       label={'Alianza'}
                                       child={<InputComponent readOnly={true} disabled={true} />}
                    />
                  </Col>
                }
                <Col md={24} xs={24}>
                  <FormItemComponent name={'name'}
                                     required={true}
                                     label={'Nombre'}
                                     child={<InputComponent />}
                  />
                </Col>
                <Col md={24} xs={24}>
                  <FormItemComponent name={'email'}
                                     required={true}
                                     label={'Correo'}
                                     newRules={[
                                       { type: 'email', message: 'No es un correo válido' }
                                     ]}
                                     child={<InputComponent type={'email'} />}
                  />
                </Col>
                <Col md={24} xs={24}>
                  <FormItemComponent name={'biography'}
                                     required={true}
                                     label={'Biografía'}
                                     child={<InputComponent type={'textarea'} />}
                  />
                </Col>
              </Col>

              <Col md={24} xs={24} className={'text-center mt-6'}>
                <ButtonComponent text={'Guardar cambios'}
                                 htmlType={'submit'}
                                 className={'ant-btn-primary'}
                                 icon={<SaveOutlined />} />
              </Col>
            </Row>
          </Form>
        </Col>
      </Col>
    </Row>
  );
}

export default Profile;