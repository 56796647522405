import React from "react";
import {Card, Col, FloatButton, Form, Space, Radio, Popconfirm, Modal, Result} from "antd";
import {ClockCircleOutlined, ProfileOutlined, SendOutlined} from "@ant-design/icons";
import useExam from "./useExam";

// Components
import FormItemComponent from "../../../subComponents/FormItemComponent";
import ButtonComponent from "../../../subComponents/ButtonComponent";

// Types
import {QuestionType} from "../../../../types/ResourceTypes";
import {ResourceType} from "../../../../types/ResourceTypes";


type PropsType = {
  resourceDetail: ResourceType;
}
const Exam = (props: PropsType) => {
  const {resourceDetail} = props;
  const {showResult, examTimer, examForm, examScoreResponse, setShowResult, checkIsCorrect, handleFinish} = useExam(props);

  return (
    <Form form={examForm}
          layout={'vertical'}>
      <Col md={24} xs={24}>
        {!examScoreResponse.graded &&
          <p className={'text-lg my-10'}>
            Selecciona la respuesta correcta de cada pregunta:
          </p>
        }
        <p className={'text-right'}>
          <b>Duración del examen:</b> {resourceDetail.exam.durationInMinutes} minutos
        </p>

        <Space direction={'vertical'} size={'middle'} className={'w-full'}>
          {resourceDetail.exam.questions.map((question: QuestionType, count: number) => (
            <Card size={"small"}
                  className={`border-[1px] 
                    ${!examScoreResponse.graded 
                      ? 'bg-snowDark border-smokeExtraDark' 
                      : checkIsCorrect(question.questionId) 
                        ? 'bg-[#bfe1bf] border-tertiary'
                        : 'bg-[#f5dfdf] border-complementary' }`}
                  key={question.questionId}
            >
              <p className={'font-semibold'}>
                {`${count + 1}. ${question.content}`}
              </p>

              <FormItemComponent name={[question.questionId, 'correctOption']}
                                 required={true}
                                 child={
                                   <Radio.Group className={'mt-6 grid grid-cols-2 gap-2 gap-y-4'}
                                                disabled={examScoreResponse.graded}
                                                size={'large'}>
                                     {question.answers.map((answer) =>
                                       <Radio key={answer.answerId} value={answer.answerId}>
                                         {answer.content}
                                       </Radio>
                                     )}
                                   </Radio.Group>
                                 }
              />

              {examScoreResponse.graded &&
                <Col className={`text-center font-bold text-md
                    ${checkIsCorrect(question.questionId) ? 'text-tertiary' : 'text-complementary'}`}>
                  {checkIsCorrect(question.questionId) ? 'Respuesta correcta' : 'Respuesta incorrecta'}
                </Col>
              }
            </Card>
          ))}
        </Space>
      </Col>

      {!examScoreResponse.graded &&
        <Col className={'w-full text-center py-5'}>
          <Popconfirm title={'¿Confirmas terminar este intento y enviar estas respuestas?'}
                      onConfirm={handleFinish}>
            <ButtonComponent text={'Terminar'}
                             className={'ant-btn-primary'}
                             icon={<SendOutlined />} />
          </Popconfirm>
        </Col>
      }

      {!examScoreResponse.graded &&
        <FloatButton.Group shape={"square"} style={{ right: 30 }}>
          <FloatButton tooltip={'Tiempo restante'}
                       shape={'square'}
                       icon={<ClockCircleOutlined />}
                       description={examTimer}
                       className={'cursor-default'} />
          <FloatButton tooltip={'Preguntas contestadas'}
                       shape={'square'}
                       icon={<ProfileOutlined />}
                       description={'0/10'}
                       className={'cursor-default'} />
        </FloatButton.Group>
      }

      {showResult &&
        <Modal open={true} footer={null} closable={true} maskClosable={true} destroyOnClose={true}
               onCancel={() => setShowResult(false)}>
          <Result icon={null}
                  title={"Tu rendimiento en este examen:"}
                  subTitle={
                    <span className={'text-6xl text-complementary'}>
                      {`${Math.round(examScoreResponse.score * 100).toFixed(1)} %`}
                    </span>
                  }
          />
        </Modal>
      }
    </Form>
  );
}

export default Exam;