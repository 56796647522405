// Library
import {useEffect} from "react";
import {Form} from "antd";
import {isAdminView} from "../accountHelpers";

// Store
import {useAppDispatch} from "../../../store/store";
import {login, loginSpecialUser} from "../../../store/redux/actions/accountActions";

// Utils
import {getWindowInformation} from "../../../utils/generalUtils";

// Types
import {AccountType} from "../../../types/AccountTypes";

// Components and Subcomponents
import {SUCCESS_MODAL} from "../../subComponents/responseModals";


const useLogin = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const {param} = getWindowInformation('confirm');

  useEffect(() => {
    if(param === 'true') {
      SUCCESS_MODAL('Tu correo ha sido confirmado correctamente. Ahora puedes iniciar sesión.');
    }
  }, []);

  const handleSubmit = (values: AccountType) => {
    if (isAdminView) {
      dispatch(loginSpecialUser(values));
    } else {
      dispatch(login(values));
    }
  };

  return {
    form,
    handleSubmit,
  }
}

export default useLogin;