import request from './requestWrapper';

// Types
import {CourseInstructorType} from "../types/CourseInstructorTypes";


function add(courseInstructor: CourseInstructorType) {
  return request({
    url: 'CourseInstructor/Add',
    method: 'POST',
    data: courseInstructor
  });
}
function remove(courseInstructor: CourseInstructorType) {
  return request({
    url: 'CourseInstructor/Delete',
    method: 'DELETE',
    data: courseInstructor
  });
}

export default {
  add, remove
}