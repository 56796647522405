import React from "react";
import {Card, List, Space} from "antd";
import baseURL from "../../../api/baseURL";

// Types
import {InstructorType} from "../../../types/InstructorTypes";

// Constants
import noImagePath from './../../../assets/images/no-image.jpg';
import {useScreenSizeValidator} from "../../../utils/generalUtils";


type PropsType = {
  instructors: InstructorType[]
};
const InstructorInfo = (props: PropsType) => {
  const { instructors } = props;
  const isSmallScreen = useScreenSizeValidator("only screen and (max-width : 639px)");

  return (
    <List
      grid={{ gutter: 16, xxl: 6, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
      dataSource={instructors}
      renderItem={(instructor) => (
        <List.Item>
          <Card hoverable
                bordered={true}
                className={'border-smokeExtraDark [&>div.ant-card-cover]:m-0'}
                cover={isSmallScreen
                  ? null
                  : <img alt={instructor.name}
                         src={instructor.imageUrl ? baseURL + instructor.imageUrl : noImagePath}
                         className={'h-56'} />
                }
          >
            <Card.Meta title={<span title={instructor.name}>{instructor.name}</span>}
                       description={
                         <Space direction={isSmallScreen ? 'horizontal' : 'vertical'}>
                           <span className={'font-semibold'} title={instructor.email}>{instructor.email}</span>
                         </Space>} />
          </Card>
        </List.Item>
      )}
    />
  );
}

export default InstructorInfo;