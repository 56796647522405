// Libraries
import React from 'react';
import {Row, Col, Space, Collapse} from 'antd';
import dayjs from "dayjs";
import useCourseDetailModal from "./useCourseDetailModal";


// Types
import {CourseType} from "../../../../types/CourseTypes";

// Utils
import {DATE_FORMAT} from "../../../../utils/generalUtils";


type PropsType = {
  courseDetail?: CourseType;
};
const CourseDetailModal = (props: PropsType) => {
  const {course, items} = useCourseDetailModal(props);

  return (
    <Row gutter={[8, 0]} className={'pt-4'}>
      <Col md={12} xs={24}>
        <Space direction={'vertical'} size={'large'}>
          <Col md={24} xs={24}>
            <p className={'font-bold'}>Nombre:</p>
            <p>{course.course.name}</p>
          </Col>
          <Col md={24} xs={24}>
            <p className={'font-bold'}>Fecha de lanzamiento:</p>
            <p>{dayjs(course.course.releaseDate).format(DATE_FORMAT)}</p>
          </Col>
          <Col md={24} xs={24}>
            <p className={'font-bold'}>Estudiantes:</p>
            <p>{course.enrolledStudents}</p>
          </Col>
          <Col md={24} xs={24}>
            <p className={'font-bold'}>Descripción:</p>
            <p>{course.course.description}</p>
          </Col>
        </Space>
      </Col>
      <Col md={12} xs={24} className={'my-10 md:my-0'}>
        <Collapse items={items}
                  size={'small'}
                  activeKey={['1', '2']} />
      </Col>
    </Row>
  );
}

export default CourseDetailModal;