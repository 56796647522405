// Libraries
import React from 'react';
import {Card, Col, Image} from 'antd';
import baseURL from "../../api/baseURL";


type CardComponentType = {
  hoverable?: boolean;
  noImageUrl: string;
  bodyHeight: string;
  className?: string;
  item: any;
  actions: React.ReactNode[] | undefined;
  description:  React.ReactNode;
};
const CardComponent = (props: CardComponentType) => {
  const { hoverable, noImageUrl, bodyHeight, className, item, actions, description } = props;

  return (
    <Card
      hoverable={hoverable}
      cover={
        <Col className={'h-32 p-0 bg-black05 rounded-t [&>div]:rounded-t [&>div]:h-full [&>div]:w-full overflow-hidden'}>
          <Image preview={false}
                 className={`w-full h-full object-cover 
                 ${hoverable && 'group-hover:opacity-60 group-hover:scale-110 transition-all ease-in'}`}
                 src={item.imageUrl
                   ? baseURL + item.imageUrl!.replaceAll('\\', '/')
                   : noImageUrl} />
        </Col>
      }
      actions={actions}
      className={'h-fit shadow shadow-tertiaryDark group ' + className}
      bodyStyle={{ height: bodyHeight }}
    >
      <Card.Meta title={<span title={item.name}>{item.name}</span>}
                 description={description} />
    </Card>
  );
}

export default CardComponent;