// Libraries
import React from 'react';
import {Row, Col, Form, Modal, Switch} from 'antd';
import useInstructorsFormModal from "./useInstructorsFormModal";

// Types
import {InstructorType} from "../../../types/InstructorTypes";

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';
import InputComponent from '../../subComponents/InputComponent';


type PropsType = {
  instructorToEdit?: InstructorType;
  onToggleModal: (visible: boolean) => void;
};
const InstructorsFormModal = (props: PropsType) => {
  const {onToggleModal} = props;
  const {form, isEditing, handleSubmit} = useInstructorsFormModal(props);

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} instructor`} width={700}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'}
                              text={'Cerrar'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'}
                              text={`${isEditing ? 'Editar' : 'Crear'}`}
                              className={'ant-btn-primary'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"} className={'pt-4'} form={form} onFinish={handleSubmit} initialValues={{}}>
        <Row gutter={[8, 0]}>
          <Col md={24} xs={24} className={'contents'}>
            <Col md={24} xs={24}>
              {isEditing &&
                <Col md={12} xs={24}>
                  <FormItemComponent name={'isActive'}
                                     required={false}
                                     valuePropName={'checked'}
                                     child={<Switch checkedChildren={'Activo'}
                                                    unCheckedChildren={'Inactivo'}
                                                    className={'w-fit'} />}
                  />
                </Col>
              }
            </Col>
            <Col md={12} xs={24}>
              <FormItemComponent name={'name'}
                                 required={true}
                                 label={'Nombre'}
                                 child={<InputComponent />}
              />
            </Col>
            <Col md={12} xs={24}>
              <FormItemComponent name={'email'}
                                 required={true}
                                 label={'Correo'}
                                 newRules={[
                                   { type: 'email', message: 'No es un correo válido' }
                                 ]}
                                 child={<InputComponent type={'email'} />}
              />
            </Col>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default InstructorsFormModal;