// Libraries
import React from 'react';
import {Row, Col, Form, Modal, Switch, DatePicker, Image} from 'antd';
import baseURL from "../../../api/baseURL";
import useAllianceFormModal from "./useAllianceFormModal";

// Types
import {AllianceType} from "../../../types/AllianceTypes";

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';
import InputComponent from '../../subComponents/InputComponent';
import UploadComponent from "../../subComponents/UploadComponent";

// Utils
import {DATE_FORMAT} from "../../../utils/generalUtils";


type PropsType = {
  allianceToEdit?: AllianceType;
  onToggleModal: (visible: boolean) => void;
};
const AllianceFormModal = (props: PropsType) => {
  const { allianceToEdit, onToggleModal } = props;
  const {form, isEditing, file, acceptFormat, setFile, handleSubmit} = useAllianceFormModal(props);

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} alianza`} width={700}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Crear'}`} className={'ant-btn-primary'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"} className={'pt-4'} form={form} onFinish={handleSubmit} initialValues={{}}>
        <Row gutter={[8, 0]}>
          <Col md={12} xs={24} className={`${!isEditing && 'flex'}`}>
            <Col md={24} xs={24} className={`text-center ${!isEditing && 'm-auto'}`}>
              {isEditing && allianceToEdit?.imageUrl &&
                <Image className={'h-40'} src={baseURL! + allianceToEdit?.imageUrl} />
              }
              <UploadComponent acceptFormat={acceptFormat}
                               uploadText={`${isEditing && allianceToEdit?.imageUrl ? 'Cambiar' : 'Cargar'} imagen de alianza`}
                               file={file as File} setFile={setFile} />
            </Col>
          </Col>
          <Col md={12} xs={24}>
            {isEditing &&
              <Col md={24} xs={24}>
                <FormItemComponent name={'isActive'}
                                   required={false}
                                   valuePropName={'checked'}
                                   child={<Switch checkedChildren={'Activa'}
                                                  unCheckedChildren={'Inactiva'}
                                                  className={'w-fit'} />}
                />
              </Col>
            }
            <Col md={24} xs={24}>
              <FormItemComponent name={'name'}
                                 required={true}
                                 label={'Nombre'}
                                 child={<InputComponent placeHolder={'Nombre'} />}
              />
            </Col>
            <Col md={24} xs={24}>
              <FormItemComponent name={'email'}
                                 required={true}
                                 label={'Correo'}
                                 newRules={[
                                   { type: 'email', message: 'No es un correo válido' }
                                 ]}
                                 child={<InputComponent placeHolder={'Correo'}
                                                        type={'email'} />}
              />
            </Col>
            <Col md={24} xs={24}>
              <FormItemComponent name={'startDate'}
                                 required={true}
                                 label={'Rango de fecha'}
                                 child={<DatePicker.RangePicker placeholder={['Fecha inicial', 'Fecha final']}
                                                                className={'w-full'}
                                                                allowClear={false}
                                                                format={DATE_FORMAT} />}
              />
            </Col>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AllianceFormModal;