// Functions and Constants
import {AccountReducerType} from '../../../types/AccountTypes';
import {isSignedIn} from '../../../utils/generalUtils';

const initialState = {
  loggedIn: isSignedIn(),
};

const accountReducer = (state = initialState, action: AccountReducerType) => {
  switch (action.type) {
    default:
      return { ...state };
  }
};

export default accountReducer;
