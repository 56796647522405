// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import courseInstructorServices from '../../../api/courseInstructorServices';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Types
import {CourseInstructorType} from "../../../types/CourseInstructorTypes";

// Store
import {getById} from "./courseActions";


export const add = (courseInstructor: CourseInstructorType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseInstructorServices.add(courseInstructor)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getById(courseInstructor.courseId));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const remove = (courseInstructor: CourseInstructorType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseInstructorServices.remove(courseInstructor)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getById(courseInstructor.courseId));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}