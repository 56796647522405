import React from "react";
import {Col, List, Row, Space} from "antd";
import {AppstoreOutlined, CalendarOutlined, EditOutlined, MailOutlined, PlusOutlined} from "@ant-design/icons";
import noImage from '../../assets/images/no-image.jpg';
import useAlliances from "./useAlliances";

// Utils
import {DATE_FORMAT, getDateFormat} from "../../utils/generalUtils";
import {LIST_PROPS} from "../../utils/componentUtils";

// Components
import ButtonComponent from "../subComponents/ButtonComponent";
import AllianceFormModal from "./formModal/AllianceFormModal";
import CardComponent from "../subComponents/CardComponent";
import CoursesModal from "./courses/CoursesModal";


type AlliancesType = {
  canCreate: boolean;
  canUpdate: boolean;
  canRemove: boolean;
};
const Alliances = (props: AlliancesType) => {
  const {
    formModalVisible, coursesModalVisible, allianceToEdit, allianceResponse,
    setPagination, handleAllianceModal, handleCoursesModal
  } = useAlliances(props);

  return (
    <Row className={'w-full flex bg-secondaryDark bg-opacity-80'}>
      <Col md={24} xs={24} className={'flex bg-[transparent] py-20'}>
        <p className={'text-center m-auto text-3xl font-bold text-white'}>ALIANZAS</p>
      </Col>
      <Col md={24} xs={24} className={'bg-snow py-10 px-5 sm:px-20'}>
        <Col md={24} xs={24} className={'text-right mb-10'}>
          <ButtonComponent text={'Nueva alianza'}
                           icon={<PlusOutlined />}
                           className={'ant-btn-primary'}
                           onClick={() => handleAllianceModal(true)} />
        </Col>

        <List {...LIST_PROPS(allianceResponse,
          (page, pageSize) => setPagination({ page, pageSize }))}
              dataSource={allianceResponse.collection}
              className={'[&>div>div>div>div]:my-4'}
              renderItem={(alliance) => (
                <List.Item className={'h-full contents'}>
                  <CardComponent noImageUrl={noImage}
                                 bodyHeight={''}
                                 item={alliance}
                                 className={'[&>div.ant-card-body]:p-4 sm:[&>div.ant-card-body]:p-6'}
                                 actions={[
                                   <span onClick={() => handleAllianceModal(true, alliance)}>
                                     <EditOutlined key={"edit"} className={'[&>svg]:fill-secondaryLight'} /> Editar
                                   </span>,
                                   <span onClick={() => handleCoursesModal(true, alliance)}>
                                     <AppstoreOutlined key={"courses"} className={'[&>svg]:fill-secondaryLight'} /> Cursos
                                   </span>,
                                 ]}
                                 description={
                                   <Space direction={'vertical'}>
                                     <p className={'text-ellipsis whitespace-nowrap overflow-hidden'} title={alliance.email}>
                                       <MailOutlined /> {alliance.email}
                                     </p>
                                     <p className={'text-tertiary'}>
                                       <CalendarOutlined />
                                       {` ${getDateFormat(alliance.startDate, DATE_FORMAT)} - ${getDateFormat(alliance.endDate, DATE_FORMAT)}`}
                                     </p>
                                   </Space>
                                 } />
                </List.Item>
              )}
        />
      </Col>

      {formModalVisible &&
        <AllianceFormModal allianceToEdit={allianceToEdit}
                           onToggleModal={handleAllianceModal} />
      }
      {coursesModalVisible &&
        <CoursesModal allianceToEdit={allianceToEdit}
                      onToggleModal={handleCoursesModal} />
      }
    </Row>
  );
}

export default Alliances;