// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import studentServices from '../../../api/studentServices';
import {studentTypes} from '../types';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Types
import {FiltersPaginationType} from "../../../types/SharedTypes";
import {StudentType} from "../../../types/StudentTypes";


export const getParams = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  dispatch({
    type: studentTypes.RESET_STUDENTS,
  });
  studentServices.getParams()
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: studentTypes.GET_STUDENT_PARAMS,
        alliances: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const getProfile = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  studentServices.getProfile()
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: studentTypes.GET_STUDENT_PROFILE,
        studentProfile: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const getAllByAllianceId = (pagination: FiltersPaginationType, allianceId?: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  dispatch({
    type: studentTypes.RESET_STUDENTS,
  });
  studentServices.getAllByAllianceId(pagination, allianceId)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: studentTypes.GET_ALL_STUDENTS,
        studentResponse: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const add = (student: StudentType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  studentServices.add(student)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAllByAllianceId({ paginationVm: { page: 1, pageSize: 10 } }, student.allianceId));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const update = (student: StudentType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  studentServices.update(student)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAllByAllianceId({ paginationVm: { page: 1, pageSize: 10 } }, student.allianceId));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const updateProfile = (student: FormData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  studentServices.updateProfile(student)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getProfile());
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const remove = (student: StudentType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  studentServices.remove(student)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAllByAllianceId({ paginationVm: { page: 1, pageSize: 10 } }, student.allianceId));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}