import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from "../../../store/store";
import {getAll} from "../../../store/redux/actions/courseStudentActions";
import * as courseStudentSelectors from '../../../store/redux/selectors/courseStudentSelectors';

// Types
import {FiltersPaginationType} from "../../../types/SharedTypes";

// Utils
import {redirectWindow, useScreenSizeValidator} from "../../../utils/generalUtils";
import {routes} from "../../../utils/routes";


const useMyCourses = () => {
  const dispatch = useAppDispatch();
  const [pagination, setPagination] = useState<FiltersPaginationType>({
    paginationVm: {
      page: 1,
      pageSize: 12,
    },
    filterVm: {}
  });
  const courseStudentResponse = useSelector(courseStudentSelectors.allCourseStudentSelector)!;
  const isSmallScreen = useScreenSizeValidator("only screen and (max-width : 575px)");

  useEffect(() => {
    dispatch(getAll(pagination));
  }, []);

  const handleCourseDetail = (courseId: string) => {
    redirectWindow(routes.courses.courseDetail.replace(':courseId', courseId));
  }

  return {
    pagination,
    courseStudentResponse,
    isSmallScreen,
    setPagination,
    handleCourseDetail,
  }
}

export default useMyCourses;