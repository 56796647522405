// Libraries
import React from 'react';
import dayjs from "dayjs";
import {Row, Col, Modal, Space, Empty, List, Popconfirm, Breadcrumb, Divider} from 'antd';
import {EyeOutlined, UserAddOutlined, UserDeleteOutlined} from "@ant-design/icons";
import useInstructorsModal from "./useInstructorsModal";

// Types
import {CourseType} from "../../../types/CourseTypes";

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';
import InstructorFormModal from "./instructorFormModal/InstructorFormModal";

// Utils
import {DATE_FORMAT} from "../../../utils/generalUtils";


type PropsType = {
  canUpdate: boolean;
  courseToEdit?: CourseType;
  onToggleModal: (visible: boolean) => void;
};
const InstructorsModal = (props: PropsType) => {
  const {courseToEdit, onToggleModal} = props;
  const {
    breadcrumbItems, modalKey, courseDetail, instructorForm, handleSteps, handleSubmit, handleRemoveInstructor
  } = useInstructorsModal(props);

  return (
    <Modal key={modalKey} title={courseToEdit?.name}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           width={breadcrumbItems!.length === 2 ? 400 : breadcrumbItems!.length === 3 ? 500 : 1000}
           footer={[
             <ButtonComponent key={'cancel'}
                              text={'Cerrar'}
                              onClick={() => onToggleModal(false)} />,
             breadcrumbItems!.length > 1 &&
               <ButtonComponent key={'back'}
                                text={'Regresar'}
                                onClick={() => handleSteps(breadcrumbItems!.length - 1)} />,
             breadcrumbItems!.length > 1 &&
               <ButtonComponent key={'submit'}
                                text={'Agregar instructor'}
                                className={'ant-btn-primary'}
                                onClick={handleSubmit} />
           ]}>
      <>
        <Breadcrumb className={'mb-4 italic'} items={breadcrumbItems} />

        {breadcrumbItems!.length === 1
          ?
          <Row gutter={[8, 0]} className={'pt-4'}>
            <Col md={12} xs={24}>
              <Space direction={'vertical'} size={'large'}>
                <Col md={24} xs={24}>
                  <p className={'font-bold'}>Nombre del curso:</p>
                  <p>{courseToEdit?.name}</p>
                </Col>
                <Col md={24} xs={24}>
                  <p className={'font-bold'}>Descripción:</p>
                  <p>{courseToEdit?.description}</p>
                </Col>
                <Col md={24} xs={24}>
                  <p className={'font-bold'}>Fecha de lanzamiento:</p>
                  <p>{dayjs(courseToEdit?.releaseDate).format(DATE_FORMAT)}</p>
                </Col>
              </Space>
            </Col>

            <Divider className={'md:hidden before:border-smokeDark after:border-smokeDark text-black05'}>
              Instructores
            </Divider>

            <Col md={12} xs={24}>
              {props.canUpdate &&
                <Col md={24} xs={24} className={'text-right'}>
                  <ButtonComponent text={'Agregar instructor'}
                                   icon={<UserAddOutlined />}
                                   className={'ant-btn-primary mb-4'}
                                   onClick={() => handleSteps(2, 'Agregar instructor')} />
                </Col>
              }
              <Col md={24} xs={24}>
                {courseDetail.courseInstructors.length === 0
                  ? <Empty description={'No hay instructores para este curso'}
                           className={'py-8'} />
                  :
                  <List itemLayout={"horizontal"}
                        dataSource={courseDetail.courseInstructors}
                        renderItem={(courseInstructor, index) => (
                          <List.Item actions={
                            [
                              ...props.canUpdate ? [
                                <Popconfirm title={'¿Confirmas la eliminación de este instructor?'}
                                            okText={'Confirmar'}
                                            onConfirm={() => handleRemoveInstructor(courseInstructor)}>
                                  <UserDeleteOutlined key={"delete"}
                                                      title={'Eliminar instructor de este curso'}
                                                      className={'cursor-pointer [&>svg]:fill-complementary'} />
                                </Popconfirm>
                              ] : []
                            ]}>
                            <List.Item.Meta
                              title={
                                <Space>
                                  <span className={'top-0'}>{`${index + 1}. `}</span>
                                  <span>{courseInstructor.instructor.name}</span>
                                </Space>
                              } />
                          </List.Item>
                        )}
                  />
                }
              </Col>
            </Col>
          </Row>
          :
          <InstructorFormModal form={instructorForm} />
        }
      </>
    </Modal>
  );
}

export default InstructorsModal;