// Libraries
import React, {useEffect} from 'react';
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from "../../../../store/store";
import {getParams} from "../../../../store/redux/actions/courseAllianceActions";
import * as courseAllianceSelectors from "../../../../store/redux/selectors/courseAllianceSelectors";


const useCourseFormModal = () => {
  const dispatch = useAppDispatch();
  const params = useSelector(courseAllianceSelectors.courseParamsSelector)!;

  useEffect(() => {
    dispatch((getParams()));
  }, []);

  return {
    params,
  }
}

export default useCourseFormModal;