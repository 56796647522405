// Libraries
import React from 'react';
import {Row, Col, Form, FormInstance} from 'antd';
import ReactQuill from 'react-quill';
import useResourceDetailModal from "./useResourceDetailModal";

// Types
import {ResourceType} from "../../../../types/ResourceTypes";

// Components
import FormItemComponent from '../../../subComponents/FormItemComponent';
import InputComponent from '../../../subComponents/InputComponent';
import SelectComponent from "../../../subComponents/SelectComponent";
import ExamSection from "./ExamSection";


type PropsType = {
  form: FormInstance<any>,
  resourceToEdit?: ResourceType;
};
const ResourceDetailModal = (props: PropsType) => {
  const {form, resourceToEdit} = props;
  const {isRichText, isExam, resourceTypes, setIsRichText, setIsExam} = useResourceDetailModal(props);

  return (
    <Form layout={"vertical"}
          className={'pt-4'}
          form={form}
          onValuesChange={(fieldChanged, values) => {
            setIsRichText(values.resourceType === 3);
            setIsExam(values.resourceType === 4);
          }}>
      <Row gutter={[8, 0]}>
        <Col md={24} xs={24}>
          <FormItemComponent name={'name'}
                             required={true}
                             label={'Nombre del recurso'}
                             child={<InputComponent />}
          />
        </Col>
        <Col md={12} xs={24}>
          <FormItemComponent name={'order'}
                             required={true}
                             label={'Orden'}
                             child={<InputComponent className={'w-44'}
                                                    type={'currency'} />}
          />
        </Col>
        <Col md={12} xs={24}>
          <FormItemComponent name={'resourceType'}
                             required={true}
                             label={'Tipo de recurso'}
                             child={<SelectComponent idIdentifier={'id'}
                                                     valueIdentifier={'name'}
                                                     list={resourceTypes} />}
          />
        </Col>
        <Col md={24} xs={24}>
          {isExam
            ? <FormItemComponent name={['exam', 'durationInMinutes']}
                                 required={true}
                                 label={'Duración del examen'}
                                 child={<InputComponent className={'w-44'}
                                                        type={'currency'}
                                                        suffix={'minutos'} />}
            />
            : <FormItemComponent name={'value'}
                                 required={true}
                                 label={'Valor'}
                                 child={isRichText
                                   ? <ReactQuill theme={"snow"}
                                                 placeholder={'Ingresa tu descripción'} />
                                   : <InputComponent placeHolder={'Link del video o enlace externo'} />
                                 }
            />
          }
        </Col>

        {isExam && <ExamSection form={form}
                                resourceToEdit={resourceToEdit} />}
      </Row>
    </Form>
  );
}

export default ResourceDetailModal;