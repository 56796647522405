// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import courseAllianceServices from '../../../api/courseAllianceServices';
import {courseAllianceTypes} from "../types";

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Types
import {FiltersPaginationType} from "../../../types/SharedTypes";
import {CourseAllianceType} from "../../../types/CourseAllianceTypes";


export const getParams = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseAllianceServices.getParams()
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: courseAllianceTypes.GET_COURSE_ALLIANCE_PARAMS,
        courses: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const getAllCourses = (pagination: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseAllianceServices.getAllCourses(pagination)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: courseAllianceTypes.GET_ALL_COURSE_ALLIANCE,
        courseAllianceResponse: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const getAllByAllianceId = (allianceId: string, pagination: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseAllianceServices.getAllByAllianceId(allianceId, pagination)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: courseAllianceTypes.GET_ALL_COURSE_ALLIANCE,
        courseAllianceResponse: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const add = (courseAlliance: CourseAllianceType, pagination: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseAllianceServices.add(courseAlliance)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAllByAllianceId(courseAlliance.allianceId, pagination));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const remove = (courseAlliance: CourseAllianceType, pagination: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  courseAllianceServices.remove(courseAlliance)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
      dispatch(getAllByAllianceId(courseAlliance.allianceId, pagination));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}