import {PaginationConfig} from "antd/es/pagination";

export const LIST_PROPS = (
    response: {page: number, pageSize: number, rowCount: number, collection: any[]},
    onChangePagination: (page: number, pageSize: number) => void
  ) => {
    return {
      grid: { gutter: 24, xxl: 6, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 },
      className: '[&>div>div>div>div>div]:h-full',
      pagination: {
        pageSize: response.pageSize,
        current: response.page,
        total: response.rowCount,
        align: 'center',
        hideOnSinglePage: true,
        onChange: onChangePagination,
      } as PaginationConfig
    }
}