// Libraries
import React from 'react';
import {Select} from 'antd';

// Utils
import {removeDiacritics} from "../../utils/generalUtils";


type SelectComponentType = {
  allowClear?: boolean;
  mode?: "multiple" | "tags" | undefined;
  placeHolder?: string;
  className?: string;
  idIdentifier: string;
  valueIdentifier: string;
  value?: any;
  list: any[];
  onChange?: (e: string) => void;
};
const SelectComponent = (props: SelectComponentType) => {
  const {allowClear, mode, placeHolder, className, idIdentifier, valueIdentifier, value, list, onChange} = props;

  return (
    <Select allowClear={allowClear} showSearch placeholder={placeHolder} value={value}
            optionFilterProp={"children"}
            mode={mode}
            className={'w-full h-9 [&>div]:h-9 [&>div>span>input]:ring-0 ' + className}
            filterOption={(input, option) => {
              return removeDiacritics(option!.title.toLowerCase()).includes(removeDiacritics(input.toLowerCase()))
            }}
            onChange={(e) => onChange ? onChange(e) : null}>
      {list.map(item =>
        <Select.Option key={item[idIdentifier]} value={item[idIdentifier]} title={item[valueIdentifier]}>
          {item[valueIdentifier]}
        </Select.Option>
      )}
    </Select>
  );
}

export default SelectComponent;