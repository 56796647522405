// Libraries
import React from "react";
const AccountView = () => {
  const { pathname } = window.location;
  const isAdminView = pathname.includes('/admin');

  return {
    isAdminView
  }
}

export default AccountView;