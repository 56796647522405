import React from 'react';
import {NavLink} from 'react-router-dom';
import {Col, Drawer, Layout, Menu, Row, Space} from 'antd';
import {EditOutlined, LogoutOutlined, MenuOutlined} from '@ant-design/icons';
import useNavbar from "./useNavbar";

// Components and Subcomponents
import ButtonComponent from "../subComponents/ButtonComponent";

// Utils
import {routes} from "../../utils/routes";
import {Logo} from "../../assets/customIcons";


const {Header} = Layout;


const Navbar = () => {
  const {
    drawerVisible, isSmallWindow, currentRoute, userName, isStudent, setDrawerVisible, handleRouteSelector,
    setNavItems
  } = useNavbar();

  return (
    <Header className={`bg-primary shadow-md shadow-black05 h-16 px-10 md:px-10 lg:px-20 z-50`}>
      <Row>
        <Col xs={6} md={3} xl={2} xxl={1} className={`h-16 px-3 text-center flex`}>
          <Logo width={'100%'}
                height={'64px'}
                fill={"white"}
                className={'cursor-pointer'} />
        </Col>
        <Col xs={18} md={21} xl={22} xxl={23} className={'h-16'}>
          {isSmallWindow
            ?
            <Col className={'h-16 border-0 bg-black text-white block text-right text-2xl'}>
              <MenuOutlined className={'h-16 w-14 [&>svg]:m-auto hover:bg-black-gray-900 cursor-pointer'}
                            onClick={() => setDrawerVisible(true)} />

              <Drawer placement={"right"} open={drawerVisible} closable={false} width={250}
                      onClose={() => setDrawerVisible(false)} className={'[&>div>div]:p-0'}
                      footer={
                        <p className={'p-5'}>
                          <ButtonComponent text={'Cerrar sesión'}
                                           icon={<LogoutOutlined />}
                                           className={'w-full ant-btn-primary'}
                                           onClick={() => handleRouteSelector('logout')} />
                        </p>
                      }>
                <Space direction={'vertical'} className={'w-full text-center py-5'}>
                  <span className={'text-lg'}>
                    {userName}
                  </span>
                  {isStudent &&
                    <span className={'text-md cursor-pointer text-primary hover:underline ' +
                      'hover:underline-offset-2 text-gray-900'}
                          onClick={() => handleRouteSelector(routes.profile)}>
                      <EditOutlined /> {'Editar perfil'}
                    </span>
                  }
                </Space>
                {setNavItems().map(item =>
                  item.key !== "userName" &&
                    <NavLink to={item.key} key={item.key}
                             className={`${item.key === currentRoute ? '[&>p]:bg-snow [&>p]:font-medium' +
                               '[&>p]:border-l-4 [&>p]:border-l-gray-900' : '' } hover:text-gray-900`}>
                      <p className={"my-2.5 h-10 px-5 flex [&>span]:my-auto border-l-4 border-white " +
                        "hover:border-l-black-gray-900"}
                         onClick={() => handleRouteSelector(item.key)}>
                        <span>{item.label}</span>
                      </p>
                    </NavLink>
                )}
              </Drawer>
            </Col>
            :
            <Menu mode={"horizontal"} selectedKeys={[currentRoute]} items={setNavItems()}
                  className={'h-16 w-full border-0 bg-primary text-snow text-right inline-block ' +
                    '[&>li]:h-16 [&>li]:mb-0 [&>li]:after:border-0 ' +
                    '[&>li:not(.ant-menu-item-selected):hover]:text-complementaryLight ' +
                    '[&>li.ant-menu-submenu:not(.ant-menu-item-selected):hover]:text-snow ' +
                    '[&>li:hover]:mb-0 [&>li.ant-menu-item-active]:text-complementaryLight ' +
                    '[&>li:hover]:mb-0 [&>li.ant-menu-item-active]:after:border-0 [&>li.ant-menu-item-active]:mb-0 ' +
                    '[&>li.ant-menu-item-active:hover]:after:border-0 [&>li.ant-menu-item-active:hover]:mb-0 ' +
                    '[&>li.ant-menu-item-selected]:text-complementaryLight [&>li.ant-menu-item-selected]:font-bold ' +
                    '[&>li.ant-menu-item-selected]:border-0 [&>li.ant-menu-submenu]:float-right ' +
                    '[&>li.ant-menu-submenu]:cursor-default [&>li.ant-menu-submenu:hover]:text-snow ' +
                    '[&>li.ant-menu-submenu]:flex [&>li.ant-menu-submenu>div]:rounded-full [&>li.ant-menu-submenu>div]:flex ' +
                    '[&>li.ant-menu-submenu>div]:h-9 [&>li.ant-menu-submenu>div]:w-9 [&>li.ant-menu-submenu>div]:m-auto ' +
                    '[&>li.ant-menu-submenu>div>span]:rounded-full [&>li.ant-menu-submenu>div>span]:cursor-default ' +
                    '[&>li.ant-menu-submenu>div>span:hover]:text-snow'
                  }
                  onClick={(item) => handleRouteSelector(item.key)}
            />
          }
        </Col>
      </Row>
    </Header>
  );
}

export default Navbar;