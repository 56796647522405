import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Col, CollapseProps, Form, List} from "antd";
import {AuditOutlined, CheckCircleOutlined, FileTextOutlined, LinkOutlined, YoutubeOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {BreadcrumbItemType, BreadcrumbSeparatorType} from "antd/es/breadcrumb/Breadcrumb";

// Utils
import {routes} from "../../../utils/routes";
import {redirectWindow} from "../../../utils/generalUtils";

// Types
import {CourseSectionType} from "../../../types/CourseSectionTypes";

// Store
import * as resourceSelectors from "../../../store/redux/selectors/resourceSelectors";
import {getParams} from "../../../store/redux/actions/resourceActions";
import {useAppDispatch} from "../../../store/store";
import {completeResource} from "../../../store/redux/actions/courseStudentResourceActions";


const useResourceDetail = () => {
  const dispatch = useAppDispatch();
  const [collapseItems, setCollapseItems] = useState<CollapseProps['items']>([]);
  const [currentResourceName, setCurrentResourceName] = useState<string | undefined>('');
  const [breadcrumbItems, setBreadcrumbItems] = useState<Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] | undefined>([]);
  const resourceParams = useSelector(resourceSelectors.resourceParamsSelector)!;
  const navigate = useNavigate();
  const { state } = useLocation();
  if(!state) {
    redirectWindow(routes.root);
  }
  const { resourceDetail, courseDetail } = state;
  let videoID;

  if(resourceDetail.resourceType === 1){
    videoID = resourceDetail.value.split('v=')[1];
    const ampersandPosition = videoID.indexOf('&');
    if(ampersandPosition != -1) {
      videoID = videoID.substring(0, ampersandPosition);
    }
  }

  useEffect(() => {
    dispatch(getParams());
    dispatch(completeResource(resourceDetail.resourceId));
  }, [resourceDetail]);

  useEffect(() => {
    const items: CollapseProps['items'] = [];
    const resourceTypes = resourceParams.resourceTypes;

    courseDetail.courseSections.map((section: CourseSectionType) => {
      const resourcesCount = section.resources.length;

      section.resources.length > 0 &&
      items.push({
        key: section.courseSectionId,
        label: <b>{section.name}</b>,
        extra: `${resourcesCount} recurso${resourcesCount === 1 ? '' : 's'}`,
        children: <List dataSource={section.resources}
                        size={'small'}
                        renderItem={(resource) => {
                          const resourceType = resourceTypes.find(type => type.id === resource.resourceType)?.name;

                          return (
                            <List.Item className={`px-0 border-0 cursor-pointer hover:bg-snowDark 
                              ${resourceDetail.resourceId === resource.resourceId && 'bg-snowDark'}`}
                                       onClick={() => {
                                         setCurrentResourceName('');
                                         navigate(
                                           routes.courses.resourceDetail.replace(':id', resource.resourceId),
                                           {state: {resourceDetail: resource, courseDetail}}
                                         )
                                       }
                                       }>
                              <Col className={'w-full grid grid-cols-12'}>
                                <Col className={'col-span-1 text-center'}>
                                  {resourceType === 'Video'
                                    ? <YoutubeOutlined />
                                    : resourceType === 'Texto enriquecido'
                                      ? <FileTextOutlined />
                                      : resourceType === 'Link externo'
                                        ? <LinkOutlined />
                                        : <AuditOutlined />
                                  }
                                </Col>

                                <Col className={'col-span-8'}>
                                  {resource.name}
                                </Col>

                                {resource.completed &&
                                  <Col className={'col-span-3 text-right'}>
                                    <CheckCircleOutlined className={'text-tertiary mr-2'} />
                                  </Col>
                                }
                              </Col>
                            </List.Item>
                          )
                        }} />,
      })
    });

    setCollapseItems(items);
    setCurrentResourceName(resourceTypes.find(type => type.id === resourceDetail.resourceType)?.name);
    handleBreadcrumbItems();
  }, [resourceParams]);

  const handleBreadcrumbItems = () => {
    setBreadcrumbItems([
      {
        title: <a href={routes.courses.courseDetail.replace(':courseId', courseDetail.course.courseId)}>
          {courseDetail.course.name}
        </a>,
      },
      {
        title: courseDetail.courseSections.find((section: CourseSectionType) => section.courseSectionId === resourceDetail.courseSectionId).name
      },
      {
        title: resourceDetail.name,
      }
      ]);
  }

  return {
    resourceDetail,
    videoID,
    collapseItems,
    currentResourceName,
    breadcrumbItems,
  }
}

export default useResourceDetail;